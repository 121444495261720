import { mockBadgeData } from '../badgeDataConfig';
import consts from '../utils/consts';

const {
  ACTIONS: { GAMIFICATION },
} = consts;

const initialState = {
  eventsDataIsFetching: false,
  badgeDataIsFetching: false,
  affiliateBadgesIsFetching: false,
  request: null,
  eventsData: {},
  badgeData: {},
  affiliateBadges: [],
  loginStreakBadges: [],
  gamificationBadges: [],
};

const findMatchingBadgeData = (sourceBadge) =>
  mockBadgeData.find((el) => {
    if (el.type !== sourceBadge.type) {
      return false;
    }
    if (el.level && el.level !== sourceBadge.level) {
      return false;
    }
    if (el.badgeData.subtype && el.badgeData.subtype !== sourceBadge.badgeData.subtype) {
      return false;
    }
    return true;
  });

const getAugmentedBadges = (badges) => {
  if (!badges?.length) {
    return badges;
  }
  const augmentedBadges = [];
  badges.forEach((badge) => {
    const matchingBadgeData = findMatchingBadgeData(badge);
    const augmentedBadge = {
      ...badge,
      badgeData: Object.assign(badge.badgeData ?? {}, matchingBadgeData?.badgeData ?? {}),
      title: matchingBadgeData?.title,
    };
    augmentedBadges.push(augmentedBadge);
  });
  return augmentedBadges;
};

export const gamification = (state = initialState, action) => {
  switch (action.type) {
    case GAMIFICATION.REQUEST_EVENTS:
      return {
        ...state,
        eventsDataIsFetching: true,
      };
    case GAMIFICATION.RECEIVE_EVENTS: {
      let notificationLoginStreak = undefined;
      let notificationFirstPayment = undefined;
      let notificationPayoutAmount = undefined;

      const notifications = action?.payload;
      notificationLoginStreak = notifications?.find((notification) => notification.type === 'login_streak');
      notificationFirstPayment = notifications?.find((notification) => notification.type === 'first_payment');
      notificationPayoutAmount = notifications?.find((notification) => notification.type === 'payout_amount');

      return {
        ...state,
        eventsDataIsFetching: false,
        eventsData: {
          notification: notificationLoginStreak,
          notificationFirstPayment,
          notificationPayoutAmount,
        },
      };
    }
    case GAMIFICATION.REQUEST_BADGES:
      return {
        ...state,
        badgeDataIsFetching: true,
      };
    case GAMIFICATION.RECEIVE_BADGES: {
      const loginStreakBadges = action.payload?.length
        ? action.payload.filter((b) => b.type === 'login_streak').sort((a, b) => a.level > b.level)
        : [];
      const augmentedBadges = action.payload ? getAugmentedBadges(action.payload) : action.payload;
      return {
        ...state,
        badgeDataIsFetching: false,
        badgeData: augmentedBadges,
        loginStreakBadges,
        gamificationBadges: augmentedBadges,
      };
    }
    case GAMIFICATION.REQUEST_AFFILIATE_BADGES:
      return {
        ...state,
        affiliateBadgesIsFetching: true,
      };
    case GAMIFICATION.RECEIVE_AFFILIATE_BADGES: {
      return {
        ...state,
        affiliateBadgesIsFetching: false,
        affiliateBadges: action.payload ?? [],
      };
    }
    default:
      return state;
  }
};
