import { safeParse } from '../src/utils/jsonHelpers';

const jerkmateNewOfferTagId = {
  prod: {
    id: '228',
  },
  staging: {
    id: '346',
  },
};

const smartLinksConfig = {
  prod: {
    topLabel: 'offerDating',
    offerDating: {
      id: 3785,
      label: 'offerDating',
      description: 'Help your traffic find perfect matches with<br />top-converting offers.',
      backOffers: [2753, 2754, 2755, 2756],
    },
    offerAI: {
      id: 9403,
      topLabel: false,
      label: 'offerAI',
      description: 'First of its kind, the AI Smartlink allows you to<br />promote all of our best AI offers.',
      backOffers: [],
    },
    offerGlobalAdult: {
      id: 3788,
      label: 'offerGlobalAdult',
      description: 'Combine 300+ Dating & Cam offers<br />available worldwide.',
      backOffers: [3471, 3472, 3473, 3474, 3475],
    },
    offerCam: {
      id: 3664,
      label: 'offerCam',
      description: 'Promote our very best offers and let your traffic <br />fulfill fantasies online.',
      backOffers: [2779, 2778, 2777, 2776, 2775],
    },
  },
  staging: {
    topLabel: 'offerDating',
    offerDating: {
      id: 480,
      label: 'offerDating',
      description: 'Help your traffic find perfect matches with<br />top-converting offers.',
      backOffers: [2753, 2754, 2755, 2756],
    },
    offerAI: {
      id: 204,
      topLabel: false,
      label: 'offerAI',
      description: 'First of its kind, the AI Smartlink allows you to<br />promote all of our best AI offers.',
      backOffers: [],
    },
    offerGlobalAdult: {
      id: 2,
      label: 'offerGlobalAdult',
      description: 'Combine 300+ Dating & Cam offers<br />available worldwide.',
      backOffers: [3471, 3472, 3473, 3474, 3475],
    },
    offerCam: {
      id: 284,
      topLabel: false,
      label: 'offerCam',
      description: 'Promote our very best offers and let your traffic <br />fulfill fantasies online.',
      backOffers: [2779, 2778, 2777, 2776, 2775],
    },
  },
};

const verticalsSubMenuConfig = {
  prod: {
    cam: '143',
    onlyFans: '230',
    dating: '112',
    ai: '233',
  },
  staging: {
    cam: '280',
    onlyFans: '380',
    dating: '224',
    ai: '381',
  },
};

const verticalsOfferForChatHead = {
  prod: {
    cam: '143',
    dating: '112',
    AI: '233',
  },
  staging: {
    cam: '280',
    dating: '224',
    AI: '381',
  },
};

const chatHeadUrl = {
  prod: 'https://www.cemiocw.com/im_jerky?&lang=en&mode=prerecorded&outlinkUrl=',
  staging: 'https://cams-widget.staging.camshq.info/im_jerky?&lang=en&mode=prerecorded&outlinkUrl=',
};

const getApiUrlBySkin = () => {
  const url = window.location.hostname;

  if (url.includes('fansrevenue')) {
    return process.env.REACT_APP_API_URL_FANSREVENUE;
  }

  if (url.includes('bluechew') || url.includes('bluerevenue')) {
    return process.env.REACT_APP_API_URL_BLUECHEW;
  }

  if (url.includes('whaleshub')) {
    return process.env.REACT_APP_API_URL_WHALESHUB;
  }

  return process.env.REACT_APP_API_URL;
};

const getOffersAudience = () => ({
  offerAudienceOffersG: process.env.REACT_APP_OFFER_AUDIENCE_G.split(','),
  offerAudienceOffers18: process.env.REACT_APP_OFFER_AUDIENCE_18.split(','),
});

const backOffersOverride = () => {
  try {
    return JSON.parse(process.env.REACT_APP_BACK_OFFERS_OVERRIDE);
  } catch (err) {
    console.log('issue at parsing back offers override', err);
    return {};
  }
};

export default {
  API_URL: getApiUrlBySkin(),
  API_URL_ADDRESS_LOCATION: process.env.REACT_APP_API_URL_ADDRESS_LOCATION,
  REFERRAL_OFFER_ID: process.env.REACT_APP_REFERRAL_OFFER_ID,
  GTM_ID: process.env.REACT_APP_GTM_ID,
  GA4_ID: 'G-30TYHMWF86',
  UA_ID: 'UA-50797824-8',
  OPT_ID: 'OPT-T8CDZ7L',
  INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID,
  AFFILIATE_MANAGER_DEFAULT_ID: process.env.REACT_APP_AFFILIATE_MANAGER_DEFAULT_ID,
  NPS_ACTIVE_GROUP: process.env.REACT_APP_NPS_ACTIVE_GROUP,
  LIVECAM_WIDGET_OFFER_ID: process.env.REACT_APP_LIVECAM_WIDGET_OFFER_ID,
  LIVECAM_WIDGET_PREVIEW_CHAPI_TOKEN: process.env.REACT_APP_LIVECAM_WIDGET_PREVIEW_CHAPI_TOKEN,
  TOPOFFER_MFC_ORDER: process.env.REACT_APP_TOPOFFER_MFC_ORDER?.split(','),
  VERSION: process.env.REACT_APP_VERSION,

  MINIMUM_AMOUNT_FOR_IDENTITY_VALIDATION: parseInt(process.env.REACT_APP_MINIMUM_AMOUNT_FOR_IDENTITY_VALIDATION),

  SMART_LINKS: smartLinksConfig[process.env.REACT_APP_ENV],

  JERKMATE_NEW_OFFER_TAG_ID: jerkmateNewOfferTagId[process.env.REACT_APP_ENV],

  VERTICALS_SUB_MENU: verticalsSubMenuConfig[process.env.REACT_APP_ENV],

  VERTICALS_OFFER_FOR_CHAT_HEAD: verticalsOfferForChatHead[process.env.REACT_APP_ENV],

  CHAT_HEAD_URL: chatHeadUrl[process.env.REACT_APP_ENV],

  SURVEY_BASE_URL: process.env.REACT_APP_SURVEY_BASE_URL,
  SURVEY_OFFER_ID: process.env.REACT_APP_SURVEY_OFFER_ID,
  SURVEY_SCRIPT_FILE_ID: process.env.REACT_APP_SURVEY_SCRIPT_FILE_ID,
  SURVEY_TEMPLATES_OFFER_ID: process.env.REACT_APP_SURVEY_TEMPLATES_OFFER_ID,

  OFFER_AUDIENCES: getOffersAudience(),

  ACH_CAN_NOTICE_URL: process.env.REACT_APP_ACH_CAN_NOTICE_URL,

  NPS: {
    DAYS_WITHOUT_PROMPT: process.env.REACT_APP_NPS_DAYS_WITHOUT_PROMPT,
    LINK: process.env.REACT_APP_NPS_LINK,
  },
  OFFERS_DISABLED_CREATIVES: {
    OVERLAY: process.env.REACT_APP_OFFERS_DISABLED_CREATIVES_OVERLAY,
  },
  VERTICAL_ADULTPAYSITE_ID: process.env.REACT_APP_VERTICAL_ADULTPAYSITE_ID,
  OVERLAY_CREATIVE_FILE_ID: process.env.REACT_APP_OVERLAY_CREATIVE_FILE_ID,
  POPCODE_CREATIVE_FILE_ID: process.env.REACT_APP_POPCODE_CREATIVE_FILE_ID,
  SIGNUP_OVERRIDE_RECAPTCHA: process.env.REACT_APP_SIGNUP_OVERRIDE_RECAPTCHA,
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  BACK_OFFERS: {
    'Adult Paysite': [2745, 2746, 2748, 2749, 2750],
    Dating: [2753, 2754, 2755, 2756],
    'Health Product': [2766],
    'Adult Gaming': [3511, 3512, 3521, 3522],
    Gaming: [],
    Gay: [2772, 2771, 2770, 2769, 2768],
    Cam: [2779, 2778, 2777, 2776, 2775],
    Survey: [2786, 2787, 2788, 2789, 2790],
    Adult: [3471, 3472, 3473, 3474, 3475],
    Mainstream: [],
  },
  BACK_OFFERS_OVERRIDE: backOffersOverride(),
  BILLING_COUNTRIES: {
    payment_methods: {
      ach: {
        excluded_countries: [
          { code: '?' },
          { code: '**' },
          { code: 'AP' },
          { code: 'CU' },
          { code: 'EU' },
          { code: 'IR' },
          { code: 'KP' },
          { code: 'RU' },
          { code: 'SY' },
          { code: 'UA' },
          { code: 'VE' },
          { code: 'INT' },
        ],
      },
      wire: {
        excluded_countries: [
          { code: '?' },
          { code: '**' },
          { code: 'AP' },
          { code: 'CU' },
          { code: 'EU' },
          { code: 'IR' },
          { code: 'KP' },
          { code: 'SY' },
          { code: 'VE' },
          { code: 'INT' },
          { code: 'UA' },
          { code: 'BR' },
          { code: 'BD' },
          { code: 'RU' },
        ],
      },
      check: {
        excluded_countries: [
          { code: '?' },
          { code: '**' },
          { code: 'AP' },
          { code: 'CU' },
          { code: 'EU' },
          { code: 'IR' },
          { code: 'KP' },
          { code: 'RU' },
          { code: 'SY' },
          { code: 'UA' },
          { code: 'VE' },
          { code: 'INT' },
        ],
      },
    },
  },
  SIGNUP_COUNTRIES: {
    excluded_countries: [{ code: 'AP' }, { code: 'EU' }, { code: '**' }, { code: '?' }, { code: 'INT' }, { code: 'RU' }],
  },
  PROFILE_COUNTRIES: {
    excluded_countries: [{ code: 'AP' }, { code: 'EU' }, { code: '**' }, { code: '?' }, { code: 'INT' }, { code: 'RU' }],
  },
  ID_APPROVAL_QUESTIONS_1: safeParse(process.env.REACT_APP_ID_APPROVAL_QUESTIONS_1),
  ID_APPROVAL_QUESTIONS_2: safeParse(process.env.REACT_APP_ID_APPROVAL_QUESTIONS_2),
  ID_APPROVAL_QUESTIONS_3: safeParse(process.env.REACT_APP_ID_APPROVAL_QUESTIONS_3),
  GEODATA_URL: process.env.REACT_APP_GEODATA_URL,
  PAYMENT_METHOD_BITCOIN_ID: process.env.REACT_APP_PAYMENT_METHOD_BITCOIN_ID,
};
