const ArrowLeftIcon = ({ isSlidable }) =>
  !isSlidable ? (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2139_54193)">
        <path
          clipRule="evenodd"
          d="M10 0.75C8.17052 0.75 6.38213 1.2925 4.86098 2.30891C3.33982 3.32531 2.15423 4.76996 1.45411 6.46018C0.754004 8.15039 0.570823 10.0103 0.927736 11.8046C1.28465 13.5989 2.16563 15.2471 3.45926 16.5407C4.7529 17.8344 6.40109 18.7154 8.19541 19.0723C9.98974 19.4292 11.8496 19.246 13.5398 18.5459C15.23 17.8458 16.6747 16.6602 17.6911 15.139C18.7075 13.6179 19.25 11.8295 19.25 10C19.25 7.54675 18.2754 5.19397 16.5407 3.45926C14.806 1.72455 12.4533 0.75 10 0.75Z"
          fillRule="evenodd"
          stroke="#333333"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M11.4424 6.52881L7.95638 9.99981L11.4424 13.4708"
          stroke="#333333"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_2139_54193">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g id="arrow-left" transform="translate(-2 -2)">
        <g id="Arrow---Left-Circle" transform="translate(22 2) rotate(90)">
          <path
            d="M.75,10A9.25,9.25,0,1,0,10,.75,9.25,9.25,0,0,0,.75,10Z"
            fill="none"
            fillRule="evenodd"
            id="Stroke-1"
            stroke="#03bc04"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M6.529,8.558,10,12.044l3.471-3.486"
            fill="none"
            fillRule="evenodd"
            id="Stroke-3"
            stroke="#03bc04"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );

export default ArrowLeftIcon;
