import util from 'util';

export const LOGIN_STREAK_TYPE = 'login_streak';
export const PAYOUT_MILESTONE_TYPE = 'payout_milestone';
export const PAYOUT_MILESTONE_TITLE = 'Summit Road';

export function snakeCaseString(str) {
  if (!str) {
    return '';
  }
  return (
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((s) => s.toLowerCase())
      .join('_')
  );
}

export const getBadgeIconPath = (badge, unlocked = true) => {
  if (!badge) {
    return '';
  } else {
    if (badge.type === 'payout_milestone') {
      return `/img/gamification/badges/icons/${badge.type}_${badge.badgeData.subtype}_${badge.level}${
        unlocked ? '' : '_locked'
      }.png`;
    } else {
      return `/img/gamification/badges/icons/${badge.type}${
        badge.badgeData?.progressive ? `_${snakeCaseString(badge.badgeData.subtype)}` : ''
      }${badge.year ? `_${badge.year}` : ''}${badge.badgeData?.progressive ? '' : `_${badge.level}`}${
        unlocked ? '' : '_locked'
      }.png`;
    }
  }
};

export const getBadgeIconSrc = (badge) => {
  if (!badge) {
    return '';
  } else {
    if (badge?.isUnlocked) {
      return badge.badgeData?.thumbnailUrl ?? getBadgeIconPath(badge);
    } else {
      return getBadgeIconPath(badge, false);
    }
  }
};

export const getBadgeName = (badge) => {
  if (badge) {
    return badge.title;
  }
  return '';
};

export const getBadgeDescriptionText = (badge) => {
  if (badge) {
    if (badge.type === 'payout_milestone' && badge.isUnlocked) {
      const formattedNumber = badge.badgeData?.value
        ? badge.badgeData?.value.toLocaleString('en-US', {
            maximumFractionDigits: 2,
            notation: 'compact',
            compactDisplay: 'short',
          })
        : '';
      return util.format(badge.badgeData?.textUnlocked, `${formattedNumber}`);
    } else {
      return badge.isUnlocked ? badge.badgeData?.textUnlocked : badge.badgeData?.textLocked;
    }
  }
  return '';
};
