import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GamificationBadge from './GamificationBadge';
import { SwiperSlider } from '../../components/reusables';
import Skeleton from '../../components/reusables/Skeleton/Skeleton';
import ArrowRightIcon from './ArrowRightIcon';
import ArrowLeftIcon from './ArrowLeftIcon';
const AchievementBadgeSlider = ({
  achievementBadges,
  dataLoaded,
  handleBadgeClick,
  logoLevel,
  sectionClassName,
  selectedBadgeId,
  title,
  uid,
  windowWidthSize,
  slidersWidth,
  loginStreakBadges,
}) => {
  const [isSlidable, setIsSlidable] = useState(false);
  return (
    <div className={sectionClassName}>
      <div className="achievement-slider badge-list" style={{ width: windowWidthSize >= 992 ? slidersWidth : 'unset' }}>
        <div className="achievement-slider-title subtitle">
          {title ? <h2>{title}</h2> : null}
          {windowWidthSize >= 992 && (
            <div className={`achievement-slider-arrows ${!isSlidable ? 'disabled' : ''}`}>
              <div className={`prev swiper-prev-${uid}`} onDoubleClick={(event) => event.stopPropagation()}>
                <ArrowLeftIcon isSlidable={isSlidable} />
              </div>
              <div className={`next swiper-next-${uid}`} onDoubleClick={(event) => event.stopPropagation()}>
                <ArrowRightIcon isSlidable={isSlidable} />
              </div>
            </div>
          )}
        </div>
        {!dataLoaded ? (
          <div style={{ margin: '10px 0 45px 0' }}>
            <Skeleton height="350px" width="100%" />
          </div>
        ) : windowWidthSize >= 992 ? (
          <SwiperSlider
            className="achievements-slides"
            isSlidable={isSlidable}
            loop={false}
            setIsSlidable={setIsSlidable}
            slidersWidth={slidersWidth}
            spaceBetween={20}
            uid={uid}
          >
            {achievementBadges.map((badge, index) => (
              <div className="slide" key={`slide-${index}`}>
                <GamificationBadge
                  badge={badge}
                  badgeId={`${uid}-${index}`}
                  gamificationBadges={loginStreakBadges}
                  handleBadgeClick={handleBadgeClick}
                  imgClassName={badge.badgeData?.subtype}
                  key={index}
                  logoLevel={logoLevel}
                  selectedBadgeId={selectedBadgeId}
                  windowWidthSize={windowWidthSize}
                />
              </div>
            ))}
          </SwiperSlider>
        ) : (
          achievementBadges.map((badge, index) => (
            <div key={`slide-${index}`}>
              <GamificationBadge
                badge={badge}
                badgeId={`${uid}-${index}`}
                gamificationBadges={loginStreakBadges}
                handleBadgeClick={handleBadgeClick}
                imgClassName={badge.badgeData?.subtype}
                logoLevel={logoLevel}
                selectedBadgeId={selectedBadgeId}
                windowWidthSize={windowWidthSize}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

AchievementBadgeSlider.propTypes = {
  achievementBadges: PropTypes.array.isRequired,
  dataLoaded: PropTypes.bool.isRequired,
  handleBadgeClick: PropTypes.func,
  logoLevel: PropTypes.number,
  sectionClassName: PropTypes.string,
  selectedBadgeId: PropTypes.string,
  slidersWidth: PropTypes.number,
  title: PropTypes.string,
  uid: PropTypes.string,
  windowWidthSize: PropTypes.number,
};

export default AchievementBadgeSlider;
