import React from 'react';

const BadgeBg = () => (
  <svg className="badge-bg" fill="none" height="97" viewBox="0 0 250 97" width="250" xmlns="http://www.w3.org/2000/svg">
    <g style={{ mixBlendMode: 'lighten' }}>
      <path
        d="M249.219 0V96.3557H189.591L182.14 81.9718H65.7138L58.2627 96.3557H0.780762V0H58.2627L65.7138 14.3888H182.14L189.591 0H249.219Z"
        fill="url(#paint0_linear_1805_2745)"
      />
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1805_2745"
        x1="0.780762"
        x2="249.219"
        y1="48.1779"
        y2="48.1779"
      >
        <stop stopColor="#1D1D1B" stopOpacity="0.04" />
        <stop offset="0.06" stopColor="#173D15" stopOpacity="0.0529" />
        <stop offset="0.13" stopColor="#10640F" stopOpacity="0.2209" />
        <stop offset="0.21" stopColor="#0A8409" stopOpacity="0.4356" />
        <stop offset="0.28" stopColor="#059D05" stopOpacity="0.6561" />
        <stop offset="0.36" stopColor="#02AE02" stopOpacity="0.8281" />
        <stop offset="0.43" stopColor="#00B900" stopOpacity="0.9604" />
        <stop offset="0.5" stopColor="#00BD00" />
        <stop offset="0.56" stopColor="#01B501" stopOpacity="0.9025" />
        <stop offset="0.64" stopColor="#059F04" stopOpacity="0.6724" />
        <stop offset="0.75" stopColor="#0B7D0A" stopOpacity="0.36" />
        <stop offset="0.88" stopColor="#144D12" stopOpacity="0.09" />
        <stop offset="1" stopColor="#1D1D1B" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default BadgeBg;
