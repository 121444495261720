export const SpecialCardFooter = () => (
  <svg width="249" height="20" viewBox="0 0 249 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.25">
      <path
        d="M249 0.470703V2C249 7.94249 245.001 20.0001 226.035 20.0001H22.5759C6.50098 20.0001 1.61035 8.18122 1.61035 2.23874V0.470703H60.3914L64.8338 8.88774H184.558L189 0.470703H249Z"
        fill="url(#paint0_linear_2233_57966)"
      />
      <g style={{ mixBlendMode: 'color-dodge' }}>
        <mask
          id="mask0_2233_57966"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="0"
          width="248"
          height="21"
        >
          <path
            d="M248.389 0.470825L248.389 4.00012C248.389 9.94261 241 20.0002 229 20.0002L21.4995 20.0002C9.49951 20.0002 1.53271 10.0001 1.53271 4.00012V0.470825H60.3909L64.8333 8.88787H184.557L189 0.470825H248.389Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2233_57966)">
          <path
            d="M116.215 1.97148C113.004 4.08643 108.576 2.93826 109.032 -1.45671C109.499 -5.95506 113.47 -10.76 116.778 -12.2419C119.314 -13.3792 126.69 -13.8743 126.989 -8.97513C127.32 -3.56259 119.526 -0.208771 116.215 1.97148Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.1"
            strokeMiterlimit="10"
          />
          <path
            d="M120.847 12.1164C112.788 17.078 103.603 11.7333 104.013 1.98969C104.389 -6.9456 112.642 -14.574 119.133 -17.1364C124.854 -19.3945 134.093 -17.382 135.729 -9.21837C137.747 0.862469 128.059 7.67528 120.847 12.1145V12.1164Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.13"
            strokeMiterlimit="10"
          />
          <path
            d="M130.189 25.1001C116.073 34.5598 99.3027 19.0705 99.0038 4.60624C98.7289 -8.6546 111.748 -18.2117 121.816 -21.311C131.14 -24.1807 140.554 -17.8001 143.358 -7.45492C144.528 -3.13936 145.38 1.63918 144.827 6.13111C143.762 14.7733 137.006 20.5329 130.189 25.1001Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.2"
            strokeMiterlimit="10"
          />
          <path
            d="M132.899 30.0797C126.955 33.4332 114.375 38.0123 107.943 32.2636C100.544 25.6489 94.8499 16.4653 94.0752 6.50626C92.7245 -10.8629 109.721 -21.574 123.79 -24.5786C136.767 -27.3499 145.479 -15.9862 148.935 -4.05843C149.79 -1.10451 150.677 1.83494 151.756 4.72017C152.718 7.29806 154.588 10.2484 154.93 12.9872C155.189 15.0607 154.746 15.1457 153.2 16.4617C151.586 17.8338 149.844 19.0505 148.094 20.2454C143.118 23.6459 137.827 27.2958 132.899 30.0761V30.0797Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M134.085 34.6521C125.913 38.7805 115.817 41.3485 106.748 35.8808C97.3728 30.2295 91.8168 18.0047 90.3059 7.77021C87.2656 -12.8201 107.404 -25.2232 126.217 -27.7929C143.009 -30.0863 151.574 -12.8362 154.924 0.0662278C155.801 3.44207 156.855 6.76797 158.516 9.87097C159.221 11.1871 162.074 14.1438 161.998 15.5669C161.954 16.4033 160.875 17.6926 160.409 18.3097C158.225 21.2129 154.794 23.1336 151.637 24.8902C145.781 28.1483 139.628 31.8523 134.086 34.6521H134.085Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M133.304 40.4263C124.002 44.8378 113.558 50.0286 104.57 41.1444C95.8202 32.4947 89.3083 20.6912 86.847 8.79923C84.5353 -2.37282 86.7398 -12.7636 96.1029 -20.1431C104.383 -26.6691 116.394 -31.75 127.138 -31.9954C136.07 -32.1992 145.293 -25.4693 150.578 -18.9415C156.095 -12.1285 158.059 -3.87759 159.667 4.47258C160.558 9.09873 162.193 12.7903 164.063 17.052C165.103 19.4228 165.488 22.0119 164.104 24.5704C162.395 27.7296 158.331 29.321 155.16 30.6887C147.797 33.8678 140.187 37.1588 133.302 40.4245L133.304 40.4263Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.35"
            strokeMiterlimit="10"
          />
          <path
            d="M131.958 45.942C126.271 48.445 120.652 53.3318 114.545 54.7595C109.356 55.9722 105.624 52.3957 102.756 48.6185C98.2732 42.716 95.3394 35.8268 91.9308 29.3243C88.5667 22.9104 85.6515 16.4169 83.7784 9.45357C80.4588 -2.88634 81.0597 -14.9751 91.8956 -23.4782C101.778 -31.2331 115.292 -36.2319 127.613 -35.9916C132.648 -35.8922 137.398 -33.4795 141.53 -30.5662C146.34 -27.174 151.11 -23.6914 154.825 -19.1245C161.267 -11.2069 163.726 -1.30681 164.885 8.54271C165.46 13.4241 166.554 17.776 167.962 22.4459C168.706 24.9182 170.668 28.5851 169.768 31.1695C168.259 35.5051 160.663 36.8587 156.837 37.9322C148.388 40.3033 139.981 42.4124 131.958 45.9438V45.942Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.4"
            strokeMiterlimit="10"
          />
          <path
            d="M129.961 51.7137C123.444 54.3504 119.585 60.829 113.583 64.7607C108.095 68.3565 104.143 63.4136 101.352 59.04C95.8184 50.363 92.2796 40.3694 88.643 30.8393C86.082 24.1254 83.2443 17.5893 81.1355 10.721C77.0265 -2.65824 75.08 -16.3877 87.4345 -26.076C98.8166 -34.9991 114.649 -38.2249 128.542 -37.9878C140.441 -37.7848 151.128 -27.5811 158.511 -18.9203C165.831 -10.3348 169.401 1.32733 169.969 12.3357C170.222 17.2211 169.274 23.0621 171.303 27.6673C172.693 30.8213 177.593 34.6165 176.94 38.3057C176.016 43.5234 164.554 44.4484 160.58 45.183C150.284 47.0851 139.105 48.0119 129.959 51.7137H129.961Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.4"
            strokeMiterlimit="10"
          />
          <path
            d="M127.449 58.0057C119.924 60.7186 117.113 70.221 112.086 76.927C107.104 83.5715 102.779 78.162 99.6693 72.7507C89.0402 54.2411 83.7035 32.5633 77.6859 12.2946C73.4048 -2.12574 68.0349 -18.4665 82.0335 -29.309C94.5282 -38.9873 114.624 -40.2349 129.455 -39.9684C143.095 -39.7236 153.121 -29.6572 161.713 -19.7922C169.653 -10.6761 175.466 2.62181 175.494 14.7264C175.508 20.9701 172.268 27.0233 176.034 32.654C178.339 36.1013 186.285 41.1263 185.992 45.7343C185.602 51.8727 169.69 52.4947 165.817 52.9952C153.047 54.6491 138.198 54.1323 127.447 58.0076L127.449 58.0057Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.4"
            strokeMiterlimit="10"
          />
          <path
            d="M125.751 63.2082C117.221 65.0594 114.555 81.095 111.403 88.4563C110.456 90.6651 108.963 94.9381 105.918 94.9995C102.532 95.0681 99.9489 89.464 98.7135 87.2246C90.6069 72.5236 85.4278 55.6572 80.5064 39.7192C77.7466 30.7794 75.3991 21.6825 72.9044 12.6831C69.0564 -1.20515 62.3178 -22.2181 77.2016 -32.0844C84.4336 -36.8776 93.921 -38.2122 102.388 -39.238C111.883 -40.3885 121.724 -41.1235 131.068 -40.9826C145.34 -40.7677 156.207 -32.1223 165.192 -21.4145C169.459 -16.3287 173.871 -10.5964 176.001 -4.26996C178.045 1.80186 179.444 8.59249 179.987 14.9479C180.315 18.7676 179.523 21.883 179.008 25.5871C178.54 28.9481 180.075 31.9334 181.917 34.7093C184.448 38.5236 187.946 41.3951 191.288 44.4961C193.14 46.2172 196.966 49.1899 196.999 52.047C197.036 55.1335 193.056 56.3453 190.623 57.0696C184.433 58.9135 177.796 59.4625 171.385 60.0513C156.22 61.4437 139.375 60.2463 125.747 63.2046L125.751 63.2082Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M125.503 68.8904C120.052 69.0488 117.668 77.6303 116.401 81.6906C114.477 87.8521 113.237 94.1901 111.497 100.4C110.802 102.881 109.486 109.74 106.351 110.831C102.553 112.153 99.475 105.368 98.2425 103.128C88.9444 86.2298 83.3604 66.9746 77.7524 48.6808C74.2249 37.1752 71.3756 25.4571 68.4746 13.8218C66.1335 4.4301 64.0806 -5.46761 64.0011 -15.1925C63.9364 -22.9853 65.8304 -29.7968 73.2086 -33.7653C81.533 -38.2415 92.0081 -38.9617 101.293 -39.7342C111.977 -40.6218 122.814 -41.0432 133.458 -40.9964C139.664 -40.9694 145.914 -40.2995 151.555 -37.4907C158.438 -34.0642 164.259 -28.459 169.362 -22.9079C173.802 -18.0788 177.064 -13.9717 178.235 -7.57787C179.658 0.191572 182.749 7.59009 184.387 15.2389C185.27 19.3658 184.204 22.9111 184.302 26.9444C184.409 31.3378 188.343 35.6951 191.164 38.7129C194.795 42.5949 198.862 46.079 202.767 49.6927C205.08 51.8336 209.766 55.026 209.991 58.5479C210.245 62.5218 204.478 63.8001 201.534 64.533C194.601 66.2597 187.358 66.8251 180.248 67.3959C162.025 68.8597 143.366 68.3754 125.505 68.8922L125.503 68.8904Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M125.621 75.4439C120.529 75.929 117.735 84.3551 116.27 88.9061C113.9 96.2677 112.684 103.904 111.133 111.454C110.336 115.338 109.601 124.204 105.623 126.559C101.722 128.868 98.4048 121.488 97.0577 119.034C91.5271 108.96 87.9263 97.7738 84.1905 86.9749C80.253 75.5929 76.4931 64.1455 73.0237 52.6181C69.2527 40.0842 66.1664 27.3396 63.5111 14.5896C61.5017 4.94802 60.2064 -5.08779 60.0177 -14.9692C59.8548 -23.5116 60.6153 -32.1921 69.4618 -36.06C78.8911 -40.1822 90.3114 -40.4656 100.448 -41.0597C112.053 -41.741 123.819 -41.8682 135.323 -41.9572C141.61 -42.0063 148.105 -42.3569 154.037 -39.7662C161.198 -36.6413 167.274 -30.9767 172.746 -25.6027C174.533 -23.8477 178.073 -21.1698 178.317 -18.4973C178.469 -16.8314 177.47 -15.1018 177.473 -13.3849C177.483 -9.81501 179.022 -6.21237 180.334 -2.95673C182.734 2.99499 185.669 8.86133 187.556 14.8439C188.315 17.2511 189.029 19.8709 189.034 22.4762C189.04 24.6526 188.52 26.5348 189.021 28.7131C190.148 33.602 194.18 37.7751 197.651 41.2052C202.235 45.7344 207.364 49.6804 212.323 53.7953C215.294 56.2607 221.656 60.3048 221.985 64.5887C222.328 69.0344 216.447 70.4187 213.018 71.2908C203.186 73.7925 192.596 74.5555 182.484 74.7172C163.544 75.0188 142.415 73.8506 125.621 75.4475V75.4439Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M126.531 82.6333C121.555 83.1672 118.168 90.6109 116.364 95.2385C113.386 102.87 111.78 110.984 110.156 118.972C109.366 122.86 108.636 126.767 107.679 130.62C107.164 132.69 106.64 136.84 104.761 138.29C100.646 141.464 96.9527 133.117 95.6543 130.577C90.3759 120.244 86.6673 109.015 82.8258 98.1179C78.2621 85.167 73.9293 72.1329 69.7959 59.0427C65.2636 44.6928 61.1321 30.1837 58.8475 15.3271C57.2961 5.24116 56.9231 -5.15972 57.0117 -15.3579C57.0874 -24.1625 57.5732 -33.5137 67.0182 -37.374C76.7753 -41.3628 89.1551 -41.0787 99.5291 -41.5836C112.14 -42.1971 125.123 -42.1392 137.413 -42.3636C143.594 -42.4758 150.361 -44.0485 156.491 -41.7863C163.589 -39.1675 170.74 -33.5825 175.928 -28.2744C176.914 -27.2663 179.16 -25.2556 178.264 -23.5997C178.022 -23.1509 176.487 -22.9029 176.055 -22.4867C175.272 -21.732 175.039 -20.7873 174.975 -19.7322C174.716 -15.4954 177.649 -10.8931 179.511 -7.28079C183.24 -0.0434311 187.624 7.04371 190.692 14.3878C191.955 17.4102 193.087 20.6715 193.146 24.0503C193.183 26.1642 192.978 27.9939 193.606 30.0788C195.198 35.3688 199.321 39.8154 203.229 43.6142C208.332 48.5748 214.048 52.8767 219.642 57.2817C223.306 60.1665 230.385 64.4793 231.82 69.2843C233.429 74.6738 223.764 76.509 220.237 77.1026C209.255 78.9504 197.723 78.9576 186.623 79.5657C166.68 80.6606 143.993 80.762 126.533 82.6351L126.531 82.6333Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M127.259 91.1528C121.867 91.4549 117.702 97.6543 115.322 102.213C111.812 108.939 109.824 116.379 108.009 123.687C106.441 130.006 105.78 138.199 102.331 143.912C98.5149 150.231 94.2666 140.495 92.6763 137.316C86.5451 125.064 82.2269 111.795 77.7154 98.9133C73.1432 85.8561 68.6907 72.7554 64.3522 59.6204C59.6659 45.4362 54.8066 31.0475 53.4095 16.1614C52.434 5.76157 53.4371 -4.92408 53.9672 -15.3547C54.4347 -24.5407 55.0127 -34.1518 64.8566 -38.1804C74.7796 -42.2416 87.6163 -41.6935 98.1615 -42.2452C111.674 -42.9525 125.72 -42.9 138.765 -43.222C144.588 -43.365 151.125 -46.0332 157.134 -44.5553C160.893 -43.6309 164.346 -41.5053 167.532 -39.4105C170.299 -37.5925 175.78 -34.9116 177.071 -31.8417C177.871 -29.9333 177.661 -30.239 175.71 -30.0689C173.19 -29.8482 170.967 -30.7075 170.584 -27.3211C170.028 -22.3916 175.506 -15.2335 177.886 -11.3622C183.119 -2.84737 189.022 5.5047 193.36 14.2638C194.877 17.3282 196.482 20.7942 196.683 24.3362C196.826 26.8833 196.504 29.1119 197.205 31.6481C198.746 37.2144 202.665 41.9865 206.611 46.1037C211.761 51.4764 217.683 56.0748 223.501 60.7167C227.753 64.1103 234.709 68.327 236.673 73.64C238.928 79.7327 228.911 80.8958 224.889 81.5941C212.558 83.7396 199.924 84.3637 187.467 85.4563C175.475 86.5073 163.503 87.7266 151.542 89.0616C143.504 89.9588 134.866 90.7241 127.259 91.151V91.1528Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M128.154 100.354C115.123 100.286 108.483 115.302 105.043 125.303C102.845 131.691 101.795 139.323 98.2293 145.164C94.8051 150.771 89.9095 142.027 88.2463 139.035C81.7594 127.371 77.784 113.964 73.3051 101.482C68.463 87.9897 63.6854 74.474 58.8322 60.9853C53.7762 46.9327 47.3852 32.4044 47.0164 17.3681C46.7528 6.6089 49.6403 -4.25664 50.7872 -14.9799C51.7793 -24.2564 52.7381 -33.9977 62.7137 -38.082C72.6192 -42.1374 85.4012 -41.7104 95.9281 -42.4599C110.517 -43.4994 125.683 -43.7589 139.811 -44.085C145.939 -44.2273 152.634 -48.0773 158.975 -46.7045C162.886 -45.8577 166.511 -43.6598 169.845 -41.6005C171.37 -40.6583 177.809 -37.3325 177.671 -35.2751C177.538 -33.3149 166.436 -41.2672 165.602 -34.8985C165.333 -32.8375 166.965 -30.2197 167.894 -28.4289C170.409 -23.5808 173.64 -19.0623 176.774 -14.5871C183.604 -4.83496 191.127 4.77666 196.766 14.945C198.776 18.568 200.697 22.3874 200.594 26.6681C200.537 29.0588 200.341 31.2442 200.826 33.6295C201.983 39.3082 205.2 44.3636 208.903 48.7901C213.662 54.4796 219.387 59.308 225.044 64.1147C228.8 67.3053 237.116 72.5192 237.92 77.7637C238.955 84.4945 229.595 86.6547 224.777 87.5195C212.032 89.8057 198.986 90.5354 186.125 91.9766C174.901 93.236 163.729 94.8304 152.608 96.7797C144.617 98.1796 136.291 100.399 128.15 100.356L128.154 100.354Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M130.024 109.455C118.136 108.822 107.944 113.733 102.241 124.641C100.689 127.611 99.4494 130.723 98.1804 133.816C97.2374 136.113 96.3386 140.065 94.4914 141.845C90.2406 145.94 85.4042 138.054 83.6508 135.18C76.864 124.055 73.0442 111.226 68.6332 99.088C64.0233 86.4037 59.3802 73.7322 54.3983 61.1818C49.0369 47.6747 40.1689 32.5831 41.0622 17.5893C41.7178 6.59254 46.7918 -3.87061 48.722 -14.7009C50.444 -24.3628 51.4957 -34.8568 61.8832 -39.2554C66.4047 -41.1708 71.4179 -41.5398 76.2451 -42.162C82.7594 -42.9994 89.2645 -43.8603 95.7972 -44.5512C110.877 -46.1483 126.613 -46.8754 141.274 -47.2697C145.265 -47.3764 148.948 -48.4543 152.818 -49.7765C155.628 -50.7369 158.164 -51.3735 161.152 -50.755C165.126 -49.932 168.998 -47.7978 172.368 -45.6618C172.537 -45.5532 179.785 -40.7132 178.989 -40.0368C177.877 -39.0909 171.403 -42.7625 170.642 -43.1206C168.119 -44.3052 162.531 -48.8848 161.5 -44.1081C160.859 -41.1437 164.529 -36.3254 165.927 -34.0989C168.502 -29.9969 171.41 -26.0992 174.302 -22.2087C183.509 -9.82114 193.915 2.18843 201.521 15.1873C203.61 18.7577 205.787 22.6156 205.389 26.9166C205.135 29.6712 204.805 32.3227 205.042 35.1008C205.512 40.5829 207.54 45.7647 210.474 50.4184C214.465 56.7524 219.92 62.0265 225.252 67.259C229.306 71.2398 235.188 75.5481 234.995 81.7772C234.785 88.509 227.775 92.8951 221.808 94.3221C216.305 95.6388 210.255 95.76 204.624 96.2881C197.858 96.9212 191.089 97.5433 184.345 98.3789C173.167 99.7626 162.128 101.821 151.245 104.684C144.434 106.475 136.706 109.815 130.026 109.459L130.024 109.455Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M130.87 118.5C121.135 117.722 107.134 112.408 98.5002 121.43C94.8177 125.28 93.1672 131.814 88.9542 134.962C84.1738 138.536 79.714 132.01 77.4445 128.564C70.6193 118.192 66.6145 106.177 62.3573 94.6521C57.9362 82.6848 53.3621 70.7664 48.0678 59.1416C44.3356 50.9459 39.9624 43.0292 36.3481 34.7864C34.0565 29.5576 32.4372 23.7815 33.1833 18.076C34.6625 6.76474 42.6372 -3.29595 45.5606 -14.4169C48.1618 -24.3127 49.396 -35.3593 60.0454 -40.1042C64.625 -42.1449 69.7756 -42.5926 74.6923 -43.2976C81.0974 -44.2165 87.427 -45.6592 93.8138 -46.7032C109.498 -49.2677 125.418 -50.8083 141.205 -51.0566C145.552 -51.1254 149.389 -52.028 153.451 -53.5686C155.874 -54.4875 158.138 -55.2342 160.789 -54.9315C164.526 -54.5074 168.363 -52.5609 171.549 -50.6905C173.958 -49.275 179.116 -46.8047 180.173 -44.1024C176.793 -43.9574 172.348 -47.0439 169.469 -48.4775C166.755 -49.8278 164.106 -51.2831 161.424 -52.6877C159.289 -53.806 157.937 -54.8373 157.368 -51.845C156.763 -48.6696 160.971 -43.4644 162.515 -41.1391C166.571 -35.0294 171.213 -29.2841 175.797 -23.5569C186.249 -10.493 197.902 2.35877 206.207 16.3451C208.557 20.3053 210.28 23.7398 209.535 28.4285C209.073 31.3338 208.701 34.2644 208.565 37.206C208.307 42.7646 209.021 48.2508 211.168 53.4252C213.662 59.4406 217.733 64.615 221.793 69.6735C225.719 74.5652 229.688 79.6417 228.898 86.2661C228.051 93.378 221.88 98.6086 215.6 101.367C210.392 103.654 205.044 103.506 199.44 103.788C192.776 104.124 186.111 104.523 179.483 105.296C169.43 106.469 159.606 108.599 150.135 112.147C144.066 114.422 136.776 118.975 130.87 118.503V118.5Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.6"
            strokeMiterlimit="10"
          />
          <path
            d="M131.286 125.632C124.735 125.143 117.605 121.022 110.866 118.46C105.641 116.473 99.7223 114.628 94.245 116.78C89.2403 118.747 87.4237 123.75 83.0625 126.201C77.9713 129.063 73.6064 123.593 70.9974 119.916C57.6121 101.064 53.0339 77.2889 42.0886 57.1108C38.0638 49.6928 33.1399 42.8439 28.8762 35.5722C25.7082 30.1686 23.0661 24.149 24.3164 17.824C25.5519 11.5767 29.7955 6.10261 33.3826 0.928442C36.8908 -4.13191 40.2058 -9.28982 42.2504 -15.1108C45.7327 -25.0273 47.3452 -36.4885 58.07 -41.5326C62.6537 -43.6879 67.8092 -44.2425 72.7533 -45.0953C78.9182 -46.1594 84.811 -48.2081 90.8638 -49.7365C107.156 -53.852 123.978 -54.8348 140.317 -54.7986C144.647 -54.7896 148.678 -55.8898 152.686 -57.6007C155.473 -58.7913 158.025 -59.8789 161.133 -59.4489C165.757 -58.8075 170.151 -56.15 174.009 -53.7508C176.163 -52.4103 180.876 -50.2785 181.773 -47.7998C178.046 -47.6824 173.03 -51.1727 169.835 -52.7517C166.496 -54.403 163.175 -56.1048 159.912 -57.897C157.992 -58.9503 155.628 -61.5157 154.872 -58.775C154.024 -55.7001 158.2 -50.6037 159.739 -48.3237C164.49 -41.2851 169.962 -34.7253 175.397 -28.1907C187.765 -13.3204 202.075 1.25363 211.401 17.4464C213.758 21.5384 214.51 24.8319 213.903 29.5399C213.554 32.2499 213.043 34.9363 212.532 37.6209C211.46 43.2684 210.395 48.9557 211.101 54.7188C212.603 66.984 224.233 77.036 221.618 89.7366C220.059 97.3081 214.311 103.49 207.652 107.27C202.2 110.363 197.482 111.187 191.268 110.981C185.722 110.798 180.161 110.744 174.623 111.118C165.345 111.745 156.365 113.756 148.135 118.099C142.914 120.854 136.855 126.053 131.284 125.636L131.286 125.632Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.6"
            strokeMiterlimit="10"
          />
          <path
            d="M131.378 132C115.74 131.873 105.532 114.666 90.0853 113.383C87.5698 113.173 84.9916 113.529 82.8775 114.856C80.8279 116.141 79.4523 118.064 77.0454 118.869C71.7141 120.653 67.4657 116.134 64.5579 112.347C51.8826 95.8382 48.1314 74.5963 36.7544 57.3767C29.1728 45.9 16.6596 33.2588 19.3796 18.3357C21.768 5.2287 32.7252 -3.83243 38.1116 -15.5799C42.7265 -25.6431 44.7467 -37.665 55.713 -43.2382C60.3592 -45.5996 65.6021 -46.2983 70.6497 -47.3905C76.8041 -48.7211 82.1538 -51.6638 88.1241 -53.4999C104.611 -58.5693 122.454 -57.9483 139.043 -57.9086C146.097 -57.8923 150.974 -61.9941 157.559 -63.6208C164.476 -65.3286 171.822 -59.9342 177.264 -56.4679C178.403 -55.7421 184.245 -52.7181 184.23 -51.3587C184.214 -49.844 180.311 -51.9798 179.256 -52.4203C174.746 -54.3033 170.36 -56.4896 165.99 -58.6614C162.957 -60.1689 158.59 -63.4204 155.298 -63.9999C154.556 -60.0533 158.2 -55.4984 160.329 -52.4708C166.165 -44.1715 172.921 -36.4157 179.462 -28.6526C192.291 -13.4298 207.106 1.7263 216.527 18.4891C218.757 22.4555 219.324 25.7575 218.838 30.3034C218.497 33.4899 217.985 36.6637 217.304 39.7979C216.07 45.4721 213.787 50.8774 212.66 56.5643C211.444 62.6935 211.892 68.3822 213.897 74.2425C216.039 80.4999 216.976 86.9035 215.177 93.3721C212.851 101.736 206.815 109.005 199.592 113.838C195.95 116.277 191.327 118.756 186.817 119.156C181.341 119.642 175.126 117.56 169.56 117.418C161.699 117.216 154.241 118.645 147.716 123.105C142.646 126.569 138.009 132.052 131.378 132Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M131.459 136.998C114.124 136.953 101.916 119.235 86.287 112.847C83.8156 111.837 81.6187 111.172 78.9925 111.891C76.6592 112.531 74.3887 113.517 71.9633 113.806C66.2684 114.484 62.0424 110.888 58.6421 106.809C46.9999 92.8454 42.5952 74.5585 32.291 59.7728C24.3808 48.4234 13.0077 34.0623 15.2985 19.394C17.394 5.97781 27.3444 -4.22798 33.3765 -15.9676C38.6751 -26.2782 42.3353 -39.1145 53.2495 -45.1885C58.0008 -47.8334 63.3731 -48.7638 68.5648 -50.2164C74.6743 -51.9255 80.0411 -55.2913 86.0659 -57.1973C102.758 -62.4782 120.666 -60.4818 137.502 -60.4475C145.523 -60.4312 150.705 -65.7988 158.098 -67.6524C165.359 -69.4717 173.324 -63.7302 179.028 -60.0916C181.137 -58.7474 186.347 -56.4259 187.438 -53.9724C188.367 -51.8875 185.263 -53.4937 183.986 -53.9526C175.394 -57.0383 167.521 -61.844 158.978 -64.9948C159.686 -63.965 159.939 -61.5315 160.379 -60.4637C161.36 -58.0862 162.808 -55.8423 164.172 -53.6671C169.83 -44.6338 176.907 -36.3449 183.708 -28.1336C196.725 -12.4139 212.272 2.97877 221.863 20.189C225.483 26.6821 223.741 34.3875 222.178 41.6755C220.85 47.8633 218.9 53.9192 216.366 59.7331C213.808 65.6029 211.714 70.2496 211.994 76.7337C212.296 83.7254 212.379 90.336 210.035 97.0658C206.99 105.806 201.141 113.738 194.006 119.727C190.503 122.666 186.294 125.559 181.747 126.67C176.758 127.889 171.918 124.841 167.01 123.882C159.314 122.379 154.478 124.211 148.365 128.697C143.247 132.453 137.863 137.018 131.461 137L131.459 136.998Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M131.988 140C115.207 139.962 99.2416 125.665 84.8337 116.948C79.093 113.476 73.9925 112.271 67.4847 111.235C61.7422 110.322 57.4031 107.38 53.6103 103.103C42.8996 91.0233 36.7929 75.1326 28.2564 61.5631C20.6377 49.4509 10.453 34.9522 12.1968 19.9236C13.8577 5.61187 23.5512 -5.778 29.5274 -18.4437C34.5636 -29.1167 40.3576 -42.0565 50.8421 -48.5735C55.4883 -51.4627 60.8041 -52.793 65.9158 -54.6224C71.9839 -56.7947 77.8184 -59.6349 83.892 -61.7329C100.711 -67.5476 118.844 -63.3753 135.86 -63.7818C142.127 -63.9306 147.034 -66.7727 152.416 -69.827C156.831 -72.3333 160.973 -73.9394 166.059 -72.3914C169.208 -71.4332 193.997 -57.5697 191.825 -55.5316C190.156 -53.9672 177.329 -60.4607 175.596 -61.1485C173.01 -62.1739 165.053 -67.0358 165.698 -61.9724C166.454 -56.047 171.091 -50.1706 174.411 -45.4139C180.021 -37.3815 186.257 -29.7918 192.546 -22.273C204.364 -8.14273 218.567 5.70988 227.185 21.2485C230.595 27.3953 228.417 36.0139 226.964 42.9194C225.553 49.6252 223.445 56.1948 220.692 62.4814C218.155 68.2726 214.158 73.6172 212.337 79.6588C210.296 86.4354 209.463 93.3717 206.718 99.974C202.833 109.322 196.898 118.008 189.829 125.298C187.079 128.133 184.007 131.171 180.387 132.9C176.453 134.781 174.295 132.59 170.493 131.316C155.865 126.411 145.178 140.029 131.99 139.998L131.988 140Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M133.058 141C115.114 140.973 98.5245 129.253 82.7159 121.033C76.5838 117.844 70.4313 114.376 64.1606 111.478C58.4235 108.828 53.4413 106.034 49.072 101.419C38.8178 90.5925 31.7811 77.067 24.558 64.268C17.1041 51.0624 7.92614 36.5012 9.102 20.7777C10.2022 6.0755 19.0239 -6.14321 24.8847 -19.2675C30.0736 -30.8861 37.1362 -44.1098 48.0216 -51.5657C57.7995 -58.2625 70.5309 -61.0154 81.5382 -65.1889C98.7091 -71.6977 117.364 -65.6733 134.48 -66.1035C141.352 -66.277 146.788 -69.9101 152.656 -73.4184C157.69 -76.4279 162.468 -78.1198 168.273 -76.1568C174.75 -73.9661 181.06 -70.0908 186.708 -66.3547C188.355 -65.2666 198.757 -59.2043 197.786 -56.8274C196.084 -52.6611 171.542 -69.9047 174.027 -56.0718C175.164 -49.7366 179.886 -43.5784 183.618 -38.4957C189.014 -31.1446 194.914 -24.1658 200.874 -17.2539C211.935 -4.42608 224.836 7.94808 233.422 22.3701C236.79 28.0276 233.916 38.137 232.602 44.3403C231.118 51.3354 228.905 58.1894 226.008 64.7488C223.254 70.9883 219.876 76.8953 216.018 82.5401C211.876 88.6024 209.517 95.5903 206.272 102.121C201.607 111.511 195.897 120.386 189.649 128.837C185.789 134.059 181.588 141.009 174.245 138.22C160.286 132.919 146.624 141.016 133.06 140.997L133.058 141Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M133.794 141.858C114.718 141.843 97.6406 133.493 80.7317 125.863C67.7618 120.01 54.9212 112.64 44.863 102.57C34.2284 91.9243 26.5518 79.389 19.9024 66.1319C12.8817 52.1349 5.48954 37.8527 6.02718 21.8651C6.53157 6.85557 14.1694 -6.49361 20.065 -20.0106C25.6151 -32.7372 33.4007 -46.117 44.7134 -54.7795C55.0302 -62.6803 68.002 -64.4255 79.9151 -68.9895C97.3284 -75.6614 115.678 -68.0402 132.67 -68.4806C145.504 -68.8126 155.802 -85.0583 169.675 -80.0377C176.428 -77.5924 182.952 -73.8477 188.929 -69.9586C192.218 -67.8182 195.405 -65.5155 198.444 -63.0467C199.497 -62.1913 203.51 -59.607 203.449 -57.981C203.338 -55.0069 193.915 -58.2174 192.291 -58.3076C185.52 -58.6884 182.13 -54.655 183.331 -48.1455C184.461 -42.024 188.87 -36.4873 192.607 -31.6291C198.043 -24.562 203.968 -17.8703 209.941 -11.2381C219.905 -0.175437 231.223 11.1399 238.952 23.4297C240.809 26.384 239.704 31.3576 239.351 34.6999C238.95 38.4879 238.348 42.2543 237.555 45.9827C236.003 53.2826 233.686 60.4382 230.658 67.2815C224.626 80.914 214.784 91.798 207.244 104.525C201.952 113.46 197.117 122.676 191.652 131.508C188.733 136.225 183.783 145.061 177.042 145C174.544 144.978 171.543 143.345 169.027 142.892C167.294 142.582 165.542 142.378 163.785 142.246C160.412 141.993 157.021 142.073 153.642 142.083C147.024 142.105 140.393 141.861 133.794 141.858Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeWidth="0.75"
            strokeMiterlimit="10"
          />
          <path
            d="M134.486 143.087C101.277 143.067 65.3124 129.367 41.1593 106.026C35.4761 100.535 31.1283 94.568 26.7971 88.0326C22.8537 82.0819 18.7977 76.1943 15.8333 69.704C8.92001 54.5691 3.09643 39.8131 3.00039 23.2004C2.91173 7.68839 9.54798 -7.19572 15.6745 -21.3743C21.4722 -34.7933 29.9425 -48.2827 41.2627 -57.9251C51.9494 -67.0281 65.5211 -68.2081 78.3725 -72.7299C86.8761 -75.7215 94.9345 -76.1455 103.896 -74.9564C112.919 -73.7601 122.329 -70.6657 130.979 -70.893C139.179 -71.1095 145.764 -76.8077 152.812 -81.1508C159.252 -85.1185 164.867 -86.2896 172.008 -83.381C178.952 -80.5517 185.614 -76.9971 191.865 -72.9067C195.108 -70.7848 198.248 -68.5059 201.264 -66.0844C203.785 -64.0599 207.123 -61.8965 208.853 -59.1449C210.083 -57.189 210.091 -56.7992 207.935 -56.4221C205.619 -56.0179 203.342 -56.3355 201.009 -55.6805C194.009 -53.7156 192.343 -47.1892 193.477 -40.7567C194.632 -34.2123 198.512 -29.3135 202.681 -24.2505C208.039 -17.7404 213.866 -11.6074 219.631 -5.44369C228.119 3.63221 238.533 13.9856 244.26 24.449C245.769 27.2061 244.556 32.9439 244.22 36.1124C243.8 40.0639 243.174 43.992 242.347 47.8822C240.727 55.4984 238.311 62.9612 235.153 70.101C232.155 76.8781 228.502 83.3684 224.25 89.4707C219.836 95.8058 214.26 101.177 209.769 107.426C203.772 115.775 199.336 125.181 194.061 133.968C190.76 139.466 185.794 149.159 178.675 150.795C174.854 151.674 169.913 149.48 165.998 148.845C162.438 148.267 158.872 147.769 155.346 147.015C148.39 145.527 141.451 143.093 134.486 143.089V143.087Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M135.254 144.85C116.389 144.833 95.1059 144.129 76.6784 136.329C62.1522 130.181 49.157 121.327 37.964 110.389C31.8362 104.4 27.8722 96.7876 23.2331 89.6968C19.4498 83.9144 14.9471 78.4782 11.7744 72.3352C4.0511 57.3818 0.341623 40.5075 0.0132845 23.9377C-0.30952 7.62524 5.08592 -8.60931 11.5826 -23.5156C17.7232 -37.6046 26.64 -51.2008 37.9972 -61.7925C48.9486 -72.0055 62.5229 -73.0782 76.2541 -77.2968C84.73 -79.9008 91.9498 -81.0986 100.876 -79.7558C110.516 -78.3061 120.513 -74.3195 129.755 -74.5714C138.415 -74.807 145.461 -81.3214 152.875 -85.9912C160.373 -90.7153 166.344 -91.1992 174.473 -87.7852C188.75 -81.7871 202.686 -73.0275 213.398 -61.8903C218.146 -56.9542 212.889 -56.6443 209.189 -53.5855C203.186 -48.6222 203.186 -40.5584 204.904 -33.6108C208.049 -20.8718 220.046 -11.9563 228.88 -2.72544C236.437 5.17167 246.376 14.3644 249.524 24.6879C250.616 28.2686 249.539 33.2175 249.137 37.0211C248.702 41.1544 248.049 45.2643 247.189 49.3324C245.505 57.2984 242.991 65.1049 239.706 72.5725C233.272 87.1961 223.92 98.7881 213.368 110.657C206.586 118.286 201.759 127.648 196.573 136.337C192.584 143.023 187.915 152.384 180.5 156.07C176.596 158.011 172.326 156.44 168.272 155.617C164.476 154.845 160.775 153.861 157.178 152.435C149.948 149.567 142.857 144.857 135.257 144.85H135.254Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M135.601 147.622C116.127 147.601 94.1876 149.751 74.9638 141.721C60.3243 135.606 44.9131 126.511 34.3245 114.678C28.0821 107.703 24.4652 98.6623 19.3583 90.9104C15.4385 84.96 10.195 79.8391 6.59467 73.7062C2.57893 66.8668 0.463054 58.2385 -1.31126 50.5968C-3.2462 42.2594 -3.91456 33.7648 -3.9958 25.31C-4.30598 -7.16494 10.8061 -40.9536 34.2987 -63.9437C39.6031 -69.1351 45.4929 -73.1864 52.6067 -75.6312C59.608 -78.0381 67.0339 -78.8115 74.1846 -80.66C78.2318 -81.7063 82.0647 -83.3398 86.2577 -83.7915C90.8901 -84.292 95.6075 -83.7662 100.162 -82.9242C109.654 -81.1678 119.48 -76.9449 128.71 -77.2087C137.872 -77.4707 145.2 -84.6317 152.96 -89.5305C156.58 -91.8163 160.618 -93.9106 165.033 -93.9973C168.866 -94.0732 172.42 -92.4614 175.882 -91.0321C191.167 -84.7185 205.22 -75.4505 216.931 -63.9455C218.687 -62.2198 221.512 -60.0551 221.344 -57.3482C221.201 -55.0696 218.759 -52.7748 217.66 -50.9118C213.209 -43.3767 214.219 -34.6834 217.049 -26.7507C219.115 -20.963 221.825 -17.0003 226.306 -12.8352C231.354 -8.14432 236.485 -3.52027 241.145 1.54829C247.364 8.3118 253.73 16.7088 254.684 25.5576C256.366 41.1427 251.073 59.6353 244.609 74.6223C238.141 89.6184 228.657 103.265 216.933 114.797C209.813 121.801 204.477 129.786 199.286 138.228C194.481 146.038 189.663 156.149 181.513 161.178C174.803 165.318 164.752 161.576 158.375 158.144C151.076 154.218 143.927 147.633 135.602 147.624L135.601 147.622Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M258.994 25.6158C259.042 60.2206 244.793 94.0078 219.894 118.51C213.139 125.158 207.125 131.398 201.909 139.39C196.282 148.01 191.242 158.668 182.932 165.194C165.654 178.762 151.907 151.691 135.618 151.677C114.589 151.657 93.5702 155.003 73.5351 146.665C65.3464 143.257 57.5088 139.035 50.173 134.093C43.3059 129.466 36.1889 124.355 31.2941 117.651C25.5519 109.786 22.2893 100.494 17.128 92.2785C13.1007 85.8662 7.37324 81.2139 2.86442 75.2502C-1.78593 69.0983 -3.50454 60.2586 -5.12756 52.9111C-7.10718 43.9537 -8.00049 34.7703 -8.00049 25.6158C-8.00049 -8.97634 6.22628 -42.7997 31.1011 -67.2787C36.744 -72.8318 42.7398 -77.7554 50.2998 -80.5302C57.2864 -83.0951 64.7398 -83.7933 71.9874 -85.3218C76.0238 -86.1737 79.8783 -87.7619 83.9368 -88.4619C88.4272 -89.2379 93.0628 -88.8201 97.5184 -88.035C107.756 -86.2298 117.756 -81.6462 128.224 -81.6516C137.954 -81.6553 145.65 -89.956 153.367 -94.7548C156.949 -96.9833 160.899 -98.9332 165.224 -98.9984C169.446 -99.0635 173.644 -97.0231 177.462 -95.4331C193.279 -88.849 207.754 -79.2278 219.896 -67.2787C222.372 -64.8422 225.886 -62.0855 226.515 -58.5112C227.011 -55.6949 225.76 -52.8134 225.103 -50.1345C222.861 -40.9836 224.71 -32.2271 229.007 -23.9137C230.184 -21.6382 231.513 -19.4278 232.928 -17.2897C234.882 -14.3341 237.448 -12.6356 240.216 -10.4722C244.482 -7.13676 248.664 -3.56431 251.993 0.709962C257.705 8.04659 258.987 16.6132 259 25.6158H258.994Z"
            stroke="#54AE32"
            strokeOpacity="0.5"
            strokeMiterlimit="10"
          />
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2233_57966"
        x1="-46.9926"
        y1="10.2343"
        x2="295.993"
        y2="10.2343"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0996442" stopColor="#00B900" stopOpacity="0.9604" />
        <stop offset="0.265125" stopColor="#079506" stopOpacity="0.5776" />
        <stop offset="0.366548" stopColor="#164314" stopOpacity="0.0729" />
        <stop offset="0.405694" stopColor="#193217" stopOpacity="0.0289" />
        <stop offset="0.5" stopColor="#1D1D1B" stopOpacity="0.04" />
        <stop offset="0.601424" stopColor="#173E15" stopOpacity="0.0576" />
        <stop offset="0.642349" stopColor="#125711" stopOpacity="0.16" />
        <stop offset="0.733096" stopColor="#05A004" stopOpacity="0.7056" />
        <stop offset="0.895018" stopColor="#01B801" stopOpacity="0.99" />
      </linearGradient>
    </defs>
  </svg>
);
