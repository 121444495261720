import { OFFER_TAG_TYPES } from './data';

export const OfferListingTagTooltipContent = ({ tag }) => {
  switch (tag) {
    case OFFER_TAG_TYPES.NEW:
      return 'Recently added. Be the first to promote it!';
    case OFFER_TAG_TYPES.EXCLUSIVE:
      return 'An opportunity for CrakRevenue Affiliates only.';
    case OFFER_TAG_TYPES.TOP_OFFER:
      return 'One of CrakRevenue’s best-performing offers.';
    case OFFER_TAG_TYPES.HOT_PICK:
      return 'Chosen by our experts for its high potential.';
    default:
      return '';
  }
};
