export const PadlockIconMini = (unlocked = true) =>
  unlocked ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="28" viewBox="0 0 21 21" fill="none">
      <path
        d="M7.54183 4.01384C7.54183 3.25346 7.84389 2.52422 8.38156 1.98655C8.91924 1.44887 9.64848 1.14681 10.4089 1.14681C11.1692 1.14681 11.8985 1.44887 12.4362 1.98655C12.9738 2.52422 13.2759 3.25346 13.2759 4.01384V10.3475H14.4227V4.01384C14.4227 2.9493 13.9998 1.92837 13.2471 1.17563C12.4943 0.422886 11.4734 0 10.4089 0C9.34432 0 8.32339 0.422886 7.57065 1.17563C6.81791 1.92837 6.39502 2.9493 6.39502 4.01384V5.82399H7.54183V4.01384Z"
        fill="#00BD00"
      />
      <g filter="url(#filter0_bd_2094_753691)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.0922 8.0293C4.489 8.0293 4 8.48273 4 9.04207V18.157C4 18.7163 4.489 19.1698 5.0922 19.1698H15.5619C16.1651 19.1698 16.6541 18.7163 16.6541 18.157V9.04207C16.6541 8.48273 16.1651 8.0293 15.5619 8.0293H5.0922ZM9.23483 12.6372C9.23483 12.0497 9.72387 11.574 10.327 11.574C10.9302 11.574 11.4192 12.0497 11.4192 12.6372C11.4192 13.0031 11.2294 13.3256 10.9405 13.5174C10.8765 13.56 10.837 13.6312 10.8479 13.7063L11.0255 14.9159C11.0411 15.0227 10.9561 15.1187 10.8453 15.1187H9.8088C9.69801 15.1187 9.61294 15.0227 9.62854 14.9159L9.80613 13.7063C9.81707 13.6312 9.7776 13.56 9.7136 13.5174C9.42469 13.3256 9.23483 13.0031 9.23483 12.6372Z"
          fill="url(#paint0_linear_2094_753691)"
        />
      </g>
      <defs>
        <filter
          id="filter0_bd_2094_753691"
          x="0"
          y="4.0293"
          width="20.6543"
          height="23.1405"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2094_753691" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_2094_753691" result="effect2_dropShadow_2094_753691" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2094_753691" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_2094_753691"
          x1="1.80786"
          y1="20.2838"
          x2="14.5432"
          y2="5.9525"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00BD00" />
          <stop offset="1" stop-color="#00BD00" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="26" viewBox="0 0 21 21" fill="none">
      <path
        d="M7.54183 4.01384C7.54183 3.25346 7.84389 2.52422 8.38156 1.98655C8.91924 1.44887 9.64848 1.14681 10.4089 1.14681C11.1692 1.14681 11.8985 1.44887 12.4362 1.98655C12.9738 2.52422 13.2759 3.25346 13.2759 4.01384V10.3475H14.4227V4.01384C14.4227 2.9493 13.9998 1.92837 13.2471 1.17563C12.4943 0.422886 11.4734 0 10.4089 0C9.34432 0 8.32339 0.422886 7.57065 1.17563C6.81791 1.92837 6.39502 2.9493 6.39502 4.01384V5.82399H7.54183V4.01384Z"
        fill="#00BD00"
      />
      <g filter="url(#filter0_bd_2145_180888)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.0922 6.0293C4.489 6.0293 4 6.48273 4 7.04207V16.157C4 16.7163 4.489 17.1698 5.0922 17.1698H15.5619C16.1651 17.1698 16.6541 16.7163 16.6541 16.157V7.04207C16.6541 6.48273 16.1651 6.0293 15.5619 6.0293H5.0922ZM9.23483 10.6372C9.23483 10.0497 9.72387 9.57399 10.327 9.57399C10.9302 9.57399 11.4192 10.0497 11.4192 10.6372C11.4192 11.0031 11.2294 11.3256 10.9405 11.5174C10.8765 11.56 10.837 11.6312 10.8479 11.7063L11.0255 12.9159C11.0411 13.0227 10.9561 13.1187 10.8453 13.1187H9.8088C9.69801 13.1187 9.61294 13.0227 9.62854 12.9159L9.80613 11.7063C9.81707 11.6312 9.7776 11.56 9.7136 11.5174C9.42469 11.3256 9.23483 11.0031 9.23483 10.6372Z"
          fill="url(#paint0_linear_2145_180888)"
        />
      </g>
      <defs>
        <filter
          id="filter0_bd_2145_180888"
          x="0"
          y="2.0293"
          width="20.6541"
          height="23.1405"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2145_180888" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_2145_180888" result="effect2_dropShadow_2145_180888" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2145_180888" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_2145_180888"
          x1="1.80786"
          y1="18.2838"
          x2="14.5432"
          y2="3.9525"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00BD00" />
          <stop offset="1" stop-color="#00BD00" />
        </linearGradient>
      </defs>
    </svg>
  );
