import { useMemo } from 'react';
import './styles/OfferListingTable.scss';
import OfferListingRow from './OfferListingRow.react';
import OfferListingRowHeader from './OfferListingRowHeader.react';
import OfferListingNoData from './OfferListingNoData.react';
import { connect } from 'react-redux';
import OfferListingSort from './OfferListingSort.react';
import { OFFER_LISTING_PAGINATION } from './data';
import { LoadingBar } from '../reusables';

const OfferListingTable = ({
  offers,
  getPaginationComponent,
  componentOfferSearch,
  offersInfos,
  offersIsFetching,
  initialOffersFetched,
}) => {
  const totalOffers = offersInfos?.total || 0;

  return (
    <div className="offerListingTable" id="offerListingTable">
      <div className="offerListingTitle">Offers</div>
      {offersIsFetching ? (
        <LoadingBar className="offerListingLoadingBar container-loader" />
      ) : (
        <div className="offerListingLoadedBar" />
      )}
      <div className="offerListingInner">
        <div className="offersSearchFilterTags">
          {componentOfferSearch}
          <div className="offerListingCountBox">
            <div className={`offerListingCount ${initialOffersFetched ? 'initialOffersFetched' : ''}`}>
              <span>{totalOffers}</span> Offers
            </div>
            <OfferListingSort />
          </div>
        </div>
        <div className="offerListingGrid">
          <OfferListingRowHeader />
          {totalOffers ? (
            <>
              {offers?.map((offer) => (
                <OfferListingRow key={offer.id} offer={offer} />
              ))}
            </>
          ) : (
            <OfferListingNoData />
          )}
        </div>
        <div className="offerListingPagination">{getPaginationComponent(OFFER_LISTING_PAGINATION)}</div>
      </div>
    </div>
  );
};

export default connect((state) => ({
  offers: state.offers.offers,
  offersInfos: state.offers.offersInfos,
  offersIsFetching: state.offers.offersIsFetching,
  initialOffersFetched: state.offers.initialOffersFetched,
}))(OfferListingTable);
