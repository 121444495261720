const ArrowRightIcon = ({ isSlidable }) =>
  !isSlidable ? (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2139_54198)">
        <path
          clipRule="evenodd"
          d="M10 19.25C11.8295 19.25 13.6179 18.7075 15.139 17.6911C16.6602 16.6747 17.8458 15.23 18.5459 13.5398C19.246 11.8496 19.4292 9.98974 19.0723 8.19541C18.7154 6.40109 17.8344 4.7529 16.5407 3.45926C15.2471 2.16563 13.5989 1.28465 11.8046 0.927736C10.0103 0.570823 8.15039 0.754004 6.46018 1.45411C4.76996 2.15423 3.32531 3.33982 2.30891 4.86098C1.2925 6.38213 0.75 8.17052 0.75 10C0.75 12.4533 1.72455 14.806 3.45926 16.5407C5.19397 18.2754 7.54675 19.25 10 19.25Z"
          fillRule="evenodd"
          stroke="#333333"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M8.55762 13.4712L12.0436 10.0002L8.55762 6.52919"
          stroke="#333333"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_2139_54198">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g id="arrow-right" transform="translate(-2 -2)">
        <g id="Arrow---Right-Circle" transform="translate(2 22) rotate(-90)">
          <path
            d="M.75,10A9.25,9.25,0,1,0,10,.75,9.25,9.25,0,0,0,.75,10Z"
            fill="none"
            fillRule="evenodd"
            id="Stroke-1"
            stroke="#03bc04"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M6.529,8.558,10,12.044l3.471-3.486"
            fill="none"
            fillRule="evenodd"
            id="Stroke-3"
            stroke="#03bc04"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );

export default ArrowRightIcon;
