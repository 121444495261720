import PropTypes from 'prop-types';
import BadgeBg from './BadgeBg';
import SelectedIconMobile from './SelectedIconMobile';
import { getBadgeIconSrc, getBadgeName } from '../../utils/gamificationHelper';
import { PadlockIcon } from './PadlockIcon';
import BadgeOwnership from './BadgeOwnership';
import { SpecialCardFooter } from './SpecialCardFooter';
import React from 'react';
import ProgressBar from './ProgressBar';

const GamificationBadge = ({
  badgeId,
  badge,
  handleBadgeClick,
  imgClassName,
  selectedBadgeId,
  windowWidthSize,
  logoLevel,
  gamificationBadges,
}) => {
  const isSelected = selectedBadgeId === badgeId;
  const iconSrc = getBadgeIconSrc(badge);
  const ownBy = (((badge?.badgeData?.affiliate_badge_count ?? 0) / 25000) * 100).toFixed(2);

  return (
    <div className="current-gamification-bagde-wrapper">
      <div
        className={`current-gamification-bagde ${isSelected ? 'selected' : ''}`}
        key={badgeId}
        onClick={() => {
          if (isSelected) {
            handleBadgeClick(null, null);
          } else {
            handleBadgeClick(badgeId, badge);
          }
        }}
      >
        {isSelected && windowWidthSize <= 992 ? <SelectedIconMobile /> : null}
        <div className="badge-box">
          <BadgeBg />
          <img alt="badge" className={`badge ${imgClassName}`} src={iconSrc} />
          {!badge?.isUnlocked ? (
            <div className="padlock">
              <PadlockIcon windowWidthSize={windowWidthSize} />
            </div>
          ) : null}
        </div>
        <span className="badge-name">{getBadgeName(badge)}</span>
        {badge.badgeData.progressive ? (
          <ProgressBar badge={badge} gamificationBadges={gamificationBadges} logoLevel={logoLevel} />
        ) : null}

        <BadgeOwnership highlightBox={windowWidthSize < 992} ownBy={ownBy} windowWidthSize={windowWidthSize} />
        {badge?.badgeData?.progressive || windowWidthSize < 992 ? null : (
          <div className="special-card-footer">{SpecialCardFooter()}</div>
        )}
      </div>
      {isSelected && windowWidthSize >= 992 ? <div className="glowing-border" /> : null}
    </div>
  );
};

GamificationBadge.propTypes = {
  badge: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    level: PropTypes.number,
    isUnlocked: PropTypes.bool,
    badgeData: {
      ctaUrl: PropTypes.string,
      progressive: PropTypes.boolean,
      style: {
        glowColorCode: PropTypes.string,
      },
      subtype: PropTypes.string,
      textLocked: PropTypes.string,
      textUnlocked: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      affiliate_badge_count: PropTypes.number,
    },
  }),
  badgeId: PropTypes.string,
  handleBadgeClick: PropTypes.func,
  imgClassName: PropTypes.string,
  selectedBadgeId: PropTypes.string,
  windowWidthSize: PropTypes.number,
};

export default GamificationBadge;
