import { connect } from 'react-redux';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import SelectedBadge from './achievements/SelectedBadge';
import AchievementBadgeSlider from './achievements/AchievementBadgeSlider';
import { getBadgeName, LOGIN_STREAK_TYPE, PAYOUT_MILESTONE_TYPE } from '../utils/gamificationHelper';
import { mockBadgeData } from '../badgeDataConfig';

const getStreakAchievementBadges = (gamificationBadges, affiliateBadges, currentLoginStreakProgress) => {
  const badgesByTypeMapObject = new Map();
  if (currentLoginStreakProgress) {
    const currentLoginStreakLevel = currentLoginStreakProgress?.progressData.streak;
    const badgeLoginStreakLevel = currentLoginStreakLevel >= 16 ? 16 : currentLoginStreakLevel;
    const progressiveLoginStreakBadge = gamificationBadges.find(
      (b) => b.type === LOGIN_STREAK_TYPE && b.level === badgeLoginStreakLevel
    );
    if (progressiveLoginStreakBadge) {
      badgesByTypeMapObject.set(getBadgeName(progressiveLoginStreakBadge), {
        ...progressiveLoginStreakBadge,
        isUnlocked: true,
      });
    }
  }
  for (const badge of gamificationBadges) {
    const unlockedBadge = affiliateBadges.find((ab) => ab.badge?.id === badge.id);
    if (badge.badgeData?.progressive) {
      if (badge.type === PAYOUT_MILESTONE_TYPE && unlockedBadge) {
        const currentLoginAchievementBadge = badgesByTypeMapObject.get(getBadgeName(badge));
        if (!currentLoginAchievementBadge || (currentLoginAchievementBadge && currentLoginAchievementBadge.level < badge.level)) {
          badgesByTypeMapObject.set(getBadgeName(badge), {
            ...badge,
            isUnlocked: true,
          });
        }
      }
    }
  }
  const loginStreakAugmentedData = mockBadgeData.find((b) => b.type === LOGIN_STREAK_TYPE);
  if (!badgesByTypeMapObject.get(getBadgeName(loginStreakAugmentedData))) {
    badgesByTypeMapObject.set(getBadgeName(loginStreakAugmentedData), {
      level: 0,
      isUnlocked: false,
      ...loginStreakAugmentedData,
      badgeData: {
        ...loginStreakAugmentedData.badgeData,
        subtype: 'bronze',
      },
    });
  }
  const payoutMileStoneAugmentedData = mockBadgeData.find((b) => b.type === PAYOUT_MILESTONE_TYPE);
  if (!badgesByTypeMapObject.get(getBadgeName(payoutMileStoneAugmentedData))) {
    badgesByTypeMapObject.set(getBadgeName(payoutMileStoneAugmentedData), {
      level: 0,
      isUnlocked: false,
      ...payoutMileStoneAugmentedData,
      badgeData: {
        ...payoutMileStoneAugmentedData.badgeData,
        subtype: 'bronze',
      },
    });
  }

  return Array.from(badgesByTypeMapObject.values());
};

const getSpecialAchievementBadges = (gamificationBadges, affiliateBadges, currentLoginStreakProgress) => {
  const currentLoginStreakLevel = currentLoginStreakProgress?.progressData.streak;
  const badgeLoginStreakLevel = currentLoginStreakLevel >= 16 ? 16 : currentLoginStreakLevel;
  const badgesByTypeMapObject = new Map();
  for (const badge of gamificationBadges) {
    const unlockedBadge = affiliateBadges.find((ab) => ab.badge?.id === badge.id);
    let isNew = false;
    if (!badge.badgeData?.progressive) {
      isNew = true;
    } else {
      if (badge.type === LOGIN_STREAK_TYPE && unlockedBadge && badge.level > badgeLoginStreakLevel) {
        const currentLoginAchievementBadge = badgesByTypeMapObject.get(getBadgeName(badge));
        if (!currentLoginAchievementBadge || (currentLoginAchievementBadge && currentLoginAchievementBadge.level < badge.level)) {
          badgesByTypeMapObject.set(getBadgeName(badge), {
            ...badge,
            isUnlocked: !!unlockedBadge,
            title: 'Login Legend History',
            badgeData: {
              ...badge.badgeData,
              progressive: false,
              textUnlocked:
                'This badge celebrates your highest streak level so far, highlighting your commitment to success. Keep pushing to set a new record!',
            },
          });
        }
      }
    }
    if (isNew && getBadgeName(badge)) {
      badgesByTypeMapObject.set(getBadgeName(badge), {
        ...badge,
        isUnlocked: !!unlockedBadge,
      });
    }
  }

  return Array.from(badgesByTypeMapObject.values());
};

const Achievements = ({
  affiliateBadges,
  gamificationAffProgress,
  gamificationBadges,
  loginStreakBadges,
  badgeDataIsFetching,
  affiliateBadgesIsFetching,
}) => {
  const [slideRight, setSlideRight] = useState(null);
  const [windowWidthSize, setWindowWidthSize] = useState(undefined);
  const [selectedBadgeId, setSelectedBadgeId] = useState(null);
  const [selectedAchievementBadge, setSelectedAchievementBadge] = useState(null);
  const achievementsContainerRef = useRef(null);
  const selectedBadgeContainerRef = useRef(null);
  const [transformY, setTransformY] = useState(0);
  const [sectionHeight, setSectionHeight] = useState('100%');
  const [hasLoaded, setHasLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [slidersWidth, setSlidersWidth] = useState('100%');
  const [selectedBadgeTimer, setSelectedBadgeTimer] = useState(null);

  const streakAchievementBadges = useMemo(
    () => (!gamificationBadges ? [] : getStreakAchievementBadges(gamificationBadges, affiliateBadges, gamificationAffProgress)),
    [affiliateBadges, gamificationBadges, gamificationAffProgress]
  );

  const specialAchievementBadges = useMemo(
    () => (!gamificationBadges ? [] : getSpecialAchievementBadges(gamificationBadges, affiliateBadges, gamificationAffProgress)),
    [gamificationBadges, affiliateBadges, gamificationAffProgress]
  );

  useEffect(() => {
    setHasLoaded(dataLoaded);
  }, [dataLoaded]);

  useEffect(() => {
    setDataLoaded(!(affiliateBadgesIsFetching && badgeDataIsFetching));
  }, [badgeDataIsFetching, affiliateBadgesIsFetching]);

  useEffect(() => {
    () => {
      selectedBadgeTimer && clearTimeout(selectedBadgeTimer);
    };
  }, [selectedBadgeTimer]);

  const handleBadgeClick = (badgeId, badge) => {
    if (selectedBadgeTimer) {
      clearTimeout(selectedBadgeTimer);
    }
    if (!badge) {
      setSelectedBadgeTimer(
        setTimeout(() => {
          setSelectedAchievementBadge(badge);
        }, 700)
      );
    } else {
      setSelectedAchievementBadge(badge);
    }
    setSelectedBadgeId(badgeId);

    if (selectedBadgeContainerRef.current && achievementsContainerRef.current) {
      const selectedBadgeHeight = selectedBadgeContainerRef.current.offsetHeight;
      const dynamicOffset = selectedBadgeHeight + 20; // 20px gap
      setTransformY(badgeId !== null ? dynamicOffset : 0);
    }

    setSlideRight(badgeId !== null);
    if (slideRight) {
      window.scroll({ top: 0, behavior: 'smooth' });
    } else {
      setTimeout(() => {
        window.scroll({ top: 0, behavior: 'smooth' });
      }, 100);
    }
  };

  useEffect(() => {
    if (windowWidthSize >= 992) {
      const achievementHeight = achievementsContainerRef.current.offsetHeight;
      setSectionHeight(achievementHeight);
    }
  }, [achievementsContainerRef?.current?.offsetHeight, windowWidthSize]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidthSize(window.innerWidth);

      if (selectedBadgeContainerRef.current && slideRight) {
        const selectedBadgeHeight = selectedBadgeContainerRef.current.offsetHeight;
        const achievementHeight = achievementsContainerRef.current.offsetHeight;

        const dynamicOffset = selectedBadgeHeight + 20; // 20px gap
        const selectedBadgeWidth = selectedBadgeContainerRef.current.offsetWidth;
        const achievementWidth = achievementsContainerRef.current.offsetWidth;
        setSlidersWidth(achievementWidth - selectedBadgeWidth);
        setTransformY(dynamicOffset);
        if (windowWidthSize <= 992) {
          setSectionHeight(selectedBadgeHeight + achievementHeight);
        }
      }

      if (windowWidthSize >= 992 && !slideRight) {
        setSlidersWidth('100%');
      }

      if (selectedBadgeId === null) {
        const achievementHeight = achievementsContainerRef.current.offsetHeight;
        setSectionHeight(achievementHeight);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [slideRight, windowWidthSize, selectedBadgeId]);

  const currentLevel = gamificationAffProgress?.progressData.streak;
  const logoLevel = currentLevel >= 16 ? 16 : currentLevel;
  return (
    <div className="container-fluid app-container" id="achievements">
      <h1>Achievements</h1>
      <div className="section-container" style={{ height: sectionHeight }}>
        <SelectedBadge
          currentLoginStreak={currentLevel}
          gamificationBadges={loginStreakBadges}
          hasLoaded={hasLoaded}
          logoLevel={logoLevel}
          selectedAchievementBadge={selectedAchievementBadge}
          selectedBadgeContainerRef={selectedBadgeContainerRef}
          slideRight={slideRight}
          windowWidthSize={windowWidthSize}
        />

        <div
          className={`achievements-container ${
            hasLoaded && slideRight !== null ? (slideRight ? 'slide-right' : 'slide-left') : ''
          }`}
          ref={achievementsContainerRef}
          style={{
            transform: windowWidthSize <= 992 ? `translateY(${transformY}px)` : 'unset',
            transition: windowWidthSize <= 992 ? 'transform 0.7s cubic-bezier(0, 1.25, 1, 1)' : 'unset',
          }}
        >
          <AchievementBadgeSlider
            achievementBadges={streakAchievementBadges}
            dataLoaded={dataLoaded}
            handleBadgeClick={handleBadgeClick}
            loginStreakBadges={loginStreakBadges}
            logoLevel={logoLevel}
            sectionClassName="streak-section"
            selectedBadgeId={selectedBadgeId}
            slidersWidth={slidersWidth}
            title="Streak Badges"
            uid="streak"
            windowWidthSize={windowWidthSize}
          />
          <AchievementBadgeSlider
            achievementBadges={specialAchievementBadges}
            dataLoaded={dataLoaded}
            handleBadgeClick={handleBadgeClick}
            logoLevel={logoLevel}
            sectionClassName="special-section"
            selectedBadgeId={selectedBadgeId}
            slidersWidth={slidersWidth}
            title="Special Badges"
            uid="special"
            windowWidthSize={windowWidthSize}
          />
        </div>
      </div>
    </div>
  );
};

export default connect((state) => ({
  affStatus: state.profile.data.affStatus,
  user: state.profile.data.affUserInfos,
  affInfos: state.profile.data.affInfos,
  gamificationEventsStats: state.gamification.eventsData?.notification?.stats,
  gamificationAffProgress: state.gamification.eventsData?.notification?.affiliateProgress,
  gamificationBadges: state.gamification.gamificationBadges,
  badgeDataIsFetching: state.gamification.badgeDataIsFetching,
  affiliateBadgesIsFetching: state.gamification.affiliateBadgesIsFetching,
  loginStreakBadges: state.gamification.loginStreakBadges,
  affiliateBadges: state.gamification.affiliateBadges,
}))(Achievements);
