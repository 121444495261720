import PropTypes from 'prop-types';
import { getBadgeIconSrc } from '../../utils/gamificationHelper';
import { PadlockIcon } from './PadlockIcon';
import BadgeOwnership from './BadgeOwnership';
import ProgressBar from './ProgressBar';
import BadgeDetails from './BadgeDetails';
import BadgeCurrentLevel from './BadgeCurrentLevel';
import BadgeCTA from './BadgeCTA';

const SelectedBadge = ({
  currentLoginStreak,
  hasLoaded,
  selectedAchievementBadge,
  selectedBadgeContainerRef,
  slideRight,
  windowWidthSize,
  gamificationBadges,
  logoLevel,
}) => {
  let glowColorCodeInitial;
  let glowColorCode50Percent;

  const ownBy = (((selectedAchievementBadge?.badgeData?.affiliate_badge_count ?? 0) / 25000) * 100).toFixed(2);
  const isProgressive = selectedAchievementBadge?.badgeData?.progressive;
  const isUnlocked = selectedAchievementBadge?.isUnlocked;
  const glowColor = selectedAchievementBadge?.badgeData?.style?.glowColorCode;

  const hexGlowColorForProgressiveBadge = (subType) => {
    switch (subType) {
      case 'bronze':
        return '#F1984D';
      case 'silver':
        return '#9F9F9F';
      case 'gold':
        return '#FFDF00';
      case 'emerald':
        return '#00b000';
    }
  };

  const hexToRgba = (hex, alpha = 1) => {
    if (selectedAchievementBadge) {
      // Remove the hash (#) if it exists
      hex = hex.replace(/^#/, '');

      // Parse the r, g, b values
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      // Return the rgba string
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
  };

  if (selectedAchievementBadge?.type === 'login_streak' || selectedAchievementBadge?.type === 'payout_milestone') {
    const subType = selectedAchievementBadge?.badgeData?.subtype;
    glowColorCodeInitial = hexToRgba(hexGlowColorForProgressiveBadge(subType), 0.9);
    glowColorCode50Percent = hexToRgba(hexGlowColorForProgressiveBadge(subType));
  } else {
    glowColorCodeInitial = hexToRgba(glowColor, 0.9);
    glowColorCode50Percent = hexToRgba(glowColor);
  }

  const level = selectedAchievementBadge?.type === 'login_streak' ? currentLoginStreak : selectedAchievementBadge?.level;
  const isLoginStreakBadge = selectedAchievementBadge?.type === 'login_streak';
  return (
    <div
      className={`selected-badge-container ${
        hasLoaded && slideRight !== null ? (slideRight ? 'visible' : 'hideSelectedBadge') : ''
      }`}
      ref={selectedBadgeContainerRef}
    >
      <div className="selected-badge">
        {windowWidthSize >= 992 ? (
          <>
            <div
              className={`achievements-badge-box ${isUnlocked && 'isUnlocked'} ${isLoginStreakBadge ? 'login-streak-icon' : ''}`}
              style={{
                '--glow-color-initial': glowColorCodeInitial,
                '--glow-color-50-percent': glowColorCode50Percent,
              }}
            >
              {selectedAchievementBadge ? (
                <>
                  <img alt="badge" src={getBadgeIconSrc(selectedAchievementBadge)} />
                  {!isUnlocked && (
                    <div className="padlock">
                      <PadlockIcon windowWidthSize={windowWidthSize} />
                    </div>
                  )}
                </>
              ) : null}
            </div>
            <BadgeCurrentLevel level={level} selectedAchievementBadge={selectedAchievementBadge} />
            {isProgressive ? (
              <ProgressBar badge={selectedAchievementBadge} gamificationBadges={gamificationBadges} logoLevel={logoLevel} />
            ) : null}
            <BadgeDetails selectedAchievementBadge={selectedAchievementBadge} />
            {selectedAchievementBadge ? <BadgeOwnership ownBy={ownBy} selected windowWidthSize={windowWidthSize} /> : null}
            {slideRight !== null ? <div className="glowing-border" /> : null}
          </>
        ) : (
          <>
            <div className="content">
              <div className={`left-side ${isProgressive ? '' : 'special-badge'}`}>
                {!isProgressive ? <BadgeCurrentLevel level={level} selectedAchievementBadge={selectedAchievementBadge} /> : null}
                <div
                  className={`achievements-badge-box ${isUnlocked && 'isUnlocked'} ${
                    isLoginStreakBadge ? 'login-streak-icon' : ''
                  }`}
                  style={{
                    '--glow-color-initial': glowColorCodeInitial,
                    '--glow-color-50-percent': glowColorCode50Percent,
                  }}
                >
                  <img alt="badge" src={getBadgeIconSrc(selectedAchievementBadge)} />
                  {!isUnlocked && (
                    <div className="padlock">
                      <PadlockIcon windowWidthSize={windowWidthSize} />
                    </div>
                  )}
                </div>
                {isProgressive ? (
                  <ProgressBar badge={selectedAchievementBadge} gamificationBadges={gamificationBadges} logoLevel={logoLevel} />
                ) : null}
              </div>
              <div className="right-side">
                {isProgressive ? (
                  <BadgeCurrentLevel level={currentLoginStreak} selectedAchievementBadge={selectedAchievementBadge} />
                ) : null}
                <BadgeDetails selectedAchievementBadge={selectedAchievementBadge} />
              </div>
            </div>

            {selectedAchievementBadge ? <BadgeOwnership ownBy={ownBy} selected windowWidthSize={windowWidthSize} /> : null}
          </>
        )}
        <BadgeCTA selectedAchievementBadge={selectedAchievementBadge} />
      </div>
    </div>
  );
};

SelectedBadge.propTypes = {
  currentLoginStreak: PropTypes.number,
  hasLoaded: PropTypes.bool,
  selectedAchievementBadge: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    level: PropTypes.number,
    isUnlocked: PropTypes.bool,
    badgeData: {
      ctaUrl: PropTypes.string,
      progressive: PropTypes.bool,
      style: {
        glowColorCode: PropTypes.string,
      },
      subtype: PropTypes.string,
      textLocked: PropTypes.string,
      textUnlocked: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      affiliate_badge_count: PropTypes.number,
    },
  }),
  selectedBadgeContainerRef: PropTypes.any,
  slideRight: PropTypes.bool,
  windowWidthSize: PropTypes.number,
};

export default SelectedBadge;
