import { getBadgeDescriptionText, getBadgeName } from '../../utils/gamificationHelper';
import PropTypes from 'prop-types';
import React from 'react';

const BadgeDetails = ({ selectedAchievementBadge }) =>
  selectedAchievementBadge ? (
    <div className="selected-badge-info">
      <h2>{getBadgeName(selectedAchievementBadge)}</h2>
      <p>{getBadgeDescriptionText(selectedAchievementBadge)}</p>
    </div>
  ) : null;

BadgeDetails.propTypes = {
  selectedAchievementBadge: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    level: PropTypes.number,
    isUnlocked: PropTypes.bool,
    badgeData: {
      ctaUrl: PropTypes.string,
      progressive: PropTypes.bool,
      style: {
        glowColorCode: PropTypes.string,
      },
      subtype: PropTypes.string,
      textLocked: PropTypes.string,
      textUnlocked: PropTypes.string,
      thumbnailUrl: PropTypes.string,
    },
  }),
};

export default BadgeDetails;
