import VerifiedPerformer from '../../../reusables/svg/offer/VerifiedPerformer.react';
import { InputText } from '../../../reusables';
import { Gtm } from '../../../../utils/gtm';
import { performerPageSearchModelSuggestions } from '../../../../pages/offer/Offer-Actions/Offer-Actions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const PerformerPageModelSelection = ({ dispatch, trendingPerformers, performerPageModelSuggestion, pname, handleSave }) => {
  const [showPerformerDropdown, setShowPerformerDropdown] = useState(false);
  const [isModelChose, setIsModelChose] = useState(false);
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      if (!isModelChose) {
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);

        return () => {
          clearTimeout(handler);
        };
      } else {
        setDebouncedValue('');
      }
    }, [value, delay]);

    return debouncedValue;
  };

  const debounceValue = useDebounce(pname.value, 100);

  useEffect(() => {
    if (debounceValue.length >= 3 && debounceValue !== '') {
      setShowPerformerDropdown(true);
      dispatch(performerPageSearchModelSuggestions(debounceValue));
    } else {
      setShowPerformerDropdown(false);
    }
  }, [debounceValue]);

  useEffect(() => {
    if (performerPageModelSuggestion.length > 0) {
      const performersDiv = document.getElementById('performers-dropdown');
      performersDiv?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  }, [performerPageModelSuggestion]);

  useEffect(() => {
    if (isModelChose) {
      setShowPerformerDropdown(true);
      setIsModelChose(false);
    }
  }, [pname]);

  return (
    <div className="model-selection-box">
      <div className="trending-models">
        <h3>Jerkmate Trending Models</h3>
        <div className="performers">
          {trendingPerformers.map((performer, i) => (
            <div
              className="performer"
              key={i}
              onClick={() => {
                setIsModelChose(true);
                setShowPerformerDropdown(false);
                pname.onChange(performer.pname);
                Gtm.event('offersList - performer page', 'Click', `trending: ${performer.pname}`);
              }}
            >
              <div className="performer-img">
                <img alt={performer.name} src={performer.imgUrl} />
              </div>
              <div className="performer-info">
                <span className="name">
                  {performer.name} <VerifiedPerformer />
                </span>
                <span className="pname">@{performer.pname}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <h4 className="performer-input-title">Cam Model Name</h4>
      <div className="performer-input-box">
        <div className="input-selection">
          <div className="selection-box">
            <InputText
              field={pname}
              id="performer-input"
              maxLength={40}
              placeholder="ex: Morgpie"
              prefixIcon="&#xE8B6;"
              tabIndex={10}
              type="text"
            />
            <span className="destination">{`Destination: www.jerkmate.com/cam/${pname.value}`}</span>
            {showPerformerDropdown && debounceValue.length > 0 && performerPageModelSuggestion.length > 0 && !isModelChose ? (
              <div className="performers" id="performers-dropdown">
                {performerPageModelSuggestion.map((performer, i) => (
                  <div
                    className="performer"
                    key={i}
                    onClick={() => {
                      setIsModelChose(true);
                      setShowPerformerDropdown(false);
                      pname.onChange(performer.nameClean);
                      Gtm.event('offersList - performer page', 'Click', `trending: ${performer.pname}`);
                    }}
                  >
                    <div className="performer-card">
                      <div className="performer-img">
                        <img alt={performer.name} src={performer.imageUrlFull} />
                      </div>
                      <div className="performer-info">
                        <span className="name">
                          {performer.name} <VerifiedPerformer />
                        </span>
                        <span className="pname">@{performer.nameClean}</span>
                      </div>
                    </div>
                    <button className="select-performer-btn">Select Model</button>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div className="performer-action-btns">
            <button
              className="waves-effect btn-large btn-flat cancel-button primary-border-color test-btn"
              onClick={() => {
                window.open(`https://www.jerkmate.com/cam/${pname.value}`, '_blank');
                Gtm.event('offersList - performer page', 'Click', 'test');
              }}
            >
              TEST
            </button>
            <button
              className="waves-effect waves-light btn"
              data-cy="landing-page-save"
              onClick={() => {
                handleSave();
                Gtm.event('offersList - performer page', 'Click', 'select');
              }}
            >
              SELECT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect((state) => ({
  performerPageModelSuggestion: state.offer.performerPageModelSuggestion,
}))(PerformerPageModelSelection);
