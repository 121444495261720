import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { Info, LoadingBar } from '../../components/reusables';
import React from 'react';
import CurrentPeriodTable from './CurrentPeriodTable';
import PaymentHistoryTable from './PaymentHistoryTable';
import NextPaymentsTable from './NextPaymentsTable';
import Tooltip from '../../components/reusables/Tooltip.react';

const PaymentTable = ({
  invoicesCarriedOverList,
  currentEarnings,
  dueDateTransform,
  handleInvoiceRequest,
  handlePaymentRequest,
  isLoadingPayments,
  minimumPayoutAmount,
  paymentsList,
  paymentTab,
  paymentTerm,
  currentPeriodPayout,
  setPaymentTab,
  hasReachedMinimumPayout,
  paginatedList,
  windowWidthSize,
  invoicesPendingArray,
}) => {
  const table = {
    head: [
      {
        component: <FormattedMessage {...messages.paymentHistoryColumnDate} />,
      },
      {
        component:
          paymentTab === 'next' ? (
            <FormattedMessage {...messages.genericTextDueDate} />
          ) : (
            <FormattedMessage {...messages.genericTextStatus} />
          ),
      },
      {
        component: <FormattedMessage {...messages.genericTextAmount} />,
      },
    ],
  };

  const setTitleTable = (selectedTab) => {
    switch (selectedTab) {
      case 'history':
        return <h2>Payment History</h2>;
      case 'current':
        return (
          <div className="current-period-title-box">
            <h2>Current Period</h2>
            <Tooltip
              className="current-period-tooltip"
              closeIcon
              content={
                <p>
                  This section shows the amount you have earned so far in the ongoing period and where you stand regarding the
                  minimum payout threshold. Carried-over earnings from previous periods are included.
                </p>
              }
              position={windowWidthSize >= 576 ? 'right' : 'bottom'}
            >
              <Info className="more-info" />
            </Tooltip>
          </div>
        );
      case 'next':
        return <h2>Next Payment</h2>;
      default:
        return null;
    }
  };

  return (
    <div className="payment-history-table table-wrapper">
      <div className={['payment-table', 'black-header', 'table-container', 'table-responsive'].join(' ')}>
        <div className="table bordered highlight">
          <div className="thead">
            <div className="header-box">
              {setTitleTable(paymentTab)}
              {paymentTab === 'current' && minimumPayoutAmount ? (
                <span className="minimum-payout-box">
                  {hasReachedMinimumPayout ? (
                    <span className="minimum-payout-title">Minimum Payout Reached</span>
                  ) : (
                    <span className="minimum-payout-not-reached-title">Minimum Payout</span>
                  )}
                  <div className="minimum-payout-numbers">
                    <FormattedNumber currency="USD" style="currency" value={currentEarnings} />
                    <span> / </span>
                    <FormattedNumber currency="USD" style="currency" value={minimumPayoutAmount} />
                  </div>
                </span>
              ) : null}
            </div>
            <div className="tr">
              {table.head.map((head, headIndex) => (
                <div className="th" key={`head-${headIndex}`}>
                  <span className="label">{head.component}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="tbody">
            {!isLoadingPayments ? (
              paymentTab === 'current' ? (
                <CurrentPeriodTable
                  currentPeriodPayout={currentPeriodPayout}
                  handleInvoiceRequest={handleInvoiceRequest}
                  paymentTerm={paymentTerm}
                  paymentsList={invoicesCarriedOverList}
                  windowWidthSize={windowWidthSize}
                />
              ) : paymentTab === 'next' ? (
                <NextPaymentsTable
                  dueDateTransform={dueDateTransform}
                  handleInvoiceRequest={handleInvoiceRequest}
                  paymentTerm={paymentTerm}
                  pendingPaymentList={invoicesPendingArray}
                  setPaymentTab={setPaymentTab}
                />
              ) : (
                <PaymentHistoryTable
                  handleInvoiceRequest={handleInvoiceRequest}
                  handlePaymentRequest={handlePaymentRequest}
                  paginatedList={paginatedList}
                  paymentsList={paymentsList}
                />
              )
            ) : (
              <span>
                <LoadingBar />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PaymentTable.defaultProps = {
  isLoadingPayments: false,
  paymentsList: [],
};

PaymentTable.propTypes = {
  handleInvoiceRequest: PropTypes.func.isRequired,
  handlePaymentRequest: PropTypes.func.isRequired,
  isLoadingPayments: PropTypes.bool,
  paymentTerm: PropTypes.string,
  paymentsList: PropTypes.array,
};

export default PaymentTable;
