import React from 'react';

const NexLvlChristmasLogo = () => (
  <svg className="next-lvl-logo" id="Layer_2" viewBox="0 0 278.41 45.7" xmlns="http://www.w3.org/2000/svg">
    <g data-name="Layer_1" id="Layer_1-2">
      <g>
        <g>
          <polygon
            points="58.28 12.84 48.02 12.84 48.02 15.42 40.5 15.42 40.5 17.97 33.06 17.97 33.06 20.55 27.93 20.55 27.93 23.1 25.5 23.1 20.59 23.1 15.24 23.1 15.24 20.55 12.71 20.55 12.71 18.48 13.49 18.48 14.12 18.48 16.31 18.48 16.31 15.66 18.8 15.66 18.8 12.89 21.56 12.89 21.56 10.07 18.74 10.07 18.74 12.84 15.98 12.84 13.16 12.84 13.16 15.66 11.3 15.66 8.48 15.66 8.46 15.66 8.46 12.84 5.64 12.84 2.82 12.84 2.82 10.07 0 10.07 0 12.89 2.82 12.89 2.82 15.66 5.64 15.66 5.64 18.48 7.07 18.48 7.07 20.55 4.53 20.55 4.53 25.68 7.35 25.68 7.35 30.8 9.89 30.8 9.89 33.36 12.71 33.36 12.71 35.93 15.24 35.93 15.24 38.49 17.67 38.49 17.67 40.69 20.15 40.69 20.15 42.88 17.77 42.88 17.77 45.7 20.59 45.7 23.41 45.7 26.23 45.7 26.23 42.88 30.85 42.88 30.85 40.31 32.1 40.31 32.1 42.94 34.86 42.94 34.86 45.7 37.68 45.7 40.5 45.7 40.5 42.88 37.68 42.88 37.68 40.31 40.5 40.31 40.5 37.75 45.36 37.75 45.36 35.18 48.02 35.18 48.02 33 50.37 33 50.37 30.8 53.15 30.8 53.15 27.88 54.99 27.88 54.99 23.1 58.28 23.1 58.28 12.84"
            style={{ fill: '#28b24b' }}
          />
          <g id="CrakRevenue_x5F_Tagline-VW">
            <g data-name="CrakRevenue_x5F_Tagline-VW" id="CrakRevenue_x5F_Tagline-VW-2">
              <g>
                <g>
                  <g>
                    <polygon
                      points="81.94 12.38 71.72 12.38 71.72 14.64 69.46 14.64 69.46 16.9 67.2 16.9 67.2 24.86 69.46 24.86 69.46 27.12 71.72 27.12 71.72 29.39 81.94 29.39 81.94 27.12 84.2 27.12 84.2 23.68 78.5 23.68 78.5 25.95 75.16 25.95 75.16 23.68 72.9 23.68 72.9 18.08 75.16 18.08 75.16 15.82 78.5 15.82 78.5 18.08 84.2 18.08 84.2 14.64 81.94 14.64 81.94 12.38"
                      style={{ fill: '#fff' }}
                    />
                    <path
                      d="M101.61,12.38h-14.75v17.01h5.7v-4.52h1.08v2.26h2.26v2.26h7.96v-3.44h-2.26v-2.26h-2.26v-1.08h4.52v-7.96h-2.26v-2.26ZM98.17,15.82v3.35h-2.26v2.26h-3.35v-5.61h5.61Z"
                      style={{ fill: '#fff' }}
                    />
                    <path
                      d="M121.28,14.64h-2.26v-2.26h-7.96v2.26h-2.26v2.26h-2.26v12.49h5.7v-4.52h5.61v4.52h5.7v-12.49h-2.26v-2.26ZM114.49,18.08v-2.26h1.08v2.26h2.26v3.35h-5.61v-3.35h2.26Z"
                      style={{ fill: '#fff' }}
                    />
                    <polygon
                      points="138.68 20.34 138.68 18.08 140.94 18.08 140.94 15.82 143.2 15.82 143.2 12.38 137.5 12.38 137.5 14.64 135.24 14.64 135.24 16.9 132.98 16.9 132.98 19.16 131.9 19.16 131.9 12.38 126.2 12.38 126.2 29.39 131.9 29.39 131.9 24.86 132.98 24.86 132.98 27.12 135.24 27.12 135.24 29.39 143.2 29.39 143.2 25.95 140.94 25.95 140.94 23.68 138.68 23.68 138.68 21.42 136.42 21.42 136.42 20.34 138.68 20.34"
                      style={{ fill: '#fff' }}
                    />
                  </g>
                  <g>
                    <path
                      d="M163.53,21.32v-6.32h-2.51v-2.72h-13.38v17.2h3.33v-5.44h4.21v2.72h2.51v2.72h5.85v-.89h-2.51v-2.72h-2.51v-4.56h5.03ZM157.68,20.44v2.72h-6.72v-9.99h9.24v7.28h-2.51Z"
                      style={{ fill: '#fff' }}
                    />
                    <polygon
                      points="169.86 13.16 169.86 20.44 179.91 20.44 179.91 21.32 169.86 21.32 169.86 28.6 182.43 28.6 182.43 29.48 166.53 29.48 166.53 12.28 182.43 12.28 182.43 13.16 169.86 13.16"
                      style={{ fill: '#fff' }}
                    />
                    <polygon
                      points="202.84 12.28 202.84 21.32 200.32 21.32 200.32 24.04 197.81 24.04 197.81 26.76 195.29 26.76 195.29 29.48 194.48 29.48 194.48 26.76 191.96 26.76 191.96 24.04 189.45 24.04 189.45 21.32 186.94 21.32 186.94 12.28 190.27 12.28 190.27 20.44 192.78 20.44 192.78 23.16 196.99 23.16 196.99 20.44 199.5 20.44 199.5 12.28 202.84 12.28"
                      style={{ fill: '#fff' }}
                    />
                    <polygon
                      points="210.03 13.16 210.03 20.44 220.08 20.44 220.08 21.32 210.03 21.32 210.03 28.6 222.6 28.6 222.6 29.48 206.7 29.48 206.7 12.28 222.6 12.28 222.6 13.16 210.03 13.16"
                      style={{ fill: '#fff' }}
                    />
                    <polygon
                      points="241.5 12.28 241.5 29.48 238.16 29.48 238.16 24.04 235.66 24.04 235.66 21.32 233.14 21.32 233.14 18.61 228.93 18.61 228.93 29.48 225.6 29.48 225.6 12.28 228.93 12.28 228.93 15 231.45 15 231.45 17.72 233.96 17.72 233.96 20.44 238.16 20.44 238.16 12.28 241.5 12.28"
                      style={{ fill: '#fff' }}
                    />
                    <polygon
                      points="259.99 12.28 259.99 26.76 257.48 26.76 257.48 29.48 246.61 29.48 246.61 26.76 244.1 26.76 244.1 12.28 247.43 12.28 247.43 28.6 256.66 28.6 256.66 12.28 259.99 12.28"
                      style={{ fill: '#fff' }}
                    />
                    <polygon
                      points="265.85 13.16 265.85 20.44 275.9 20.44 275.9 21.32 265.85 21.32 265.85 28.6 278.41 28.6 278.41 29.48 262.51 29.48 262.51 12.28 278.41 12.28 278.41 13.16 265.85 13.16"
                      style={{ fill: '#fff' }}
                    />
                  </g>
                </g>
                <g>
                  <path d="M66.91,41v-5.79h5.79v.83h-4.14v1.66h3.31v.83h-3.31v2.48h-1.66Z" style={{ fill: '#fff' }} />
                  <path
                    d="M74.36,41v-.83h-.83v-2.48h.83v-.83h4.14v.83h.83v2.48h-.83v.83h-4.14ZM75.19,40.17h2.48v-2.48h-2.48v2.48Z"
                    style={{ fill: '#fff' }}
                  />
                  <path d="M80.98,41v-.83h1.66v-4.14h-.83v-.83h2.48v4.97h1.66v.83h-4.97Z" style={{ fill: '#fff' }} />
                  <path d="M87.6,41v-.83h1.66v-4.14h-.83v-.83h2.48v4.97h1.66v.83h-4.97Z" style={{ fill: '#fff' }} />
                  <path
                    d="M94.22,41v-.83h-.83v-2.48h.83v-.83h4.14v.83h.83v2.48h-.83v.83h-4.14ZM95.05,40.17h2.48v-2.48h-2.48v2.48Z"
                    style={{ fill: '#fff' }}
                  />
                  <path
                    d="M101.67,40.17h.83v.83h-1.66v-.83h-.83v-3.31h1.66v3.31ZM103.33,36.86v3.31h-.83v-3.31h.83ZM105.81,36.86v3.31h-.83v.83h-1.66v-.83h.83v-3.31h1.66Z"
                    style={{ fill: '#fff' }}
                  />
                  <path
                    d="M115.74,41v-3.31h-1.66v-.83h1.66v-1.66h1.66v1.66h1.66v.83h-1.66v3.31h-1.66Z"
                    style={{ fill: '#fff' }}
                  />
                  <path d="M119.88,41v-5.79h1.66v1.66h3.31v.83h.83v3.31h-1.66v-3.31h-2.48v3.31h-1.66Z" style={{ fill: '#fff' }} />
                  <path
                    d="M127.33,41v-.83h-.83v-2.48h.83v-.83h4.14v.83h.83v1.66h-4.14v.83h3.31v.83h-4.14ZM128.16,38.52h2.48v-.83h-2.48v.83Z"
                    style={{ fill: '#fff' }}
                  />
                  <path
                    d="M140.57,41v-.83h-.83v-4.97h1.66v3.31h.83v-3.31h.83v3.31h.83v-3.31h1.66v4.97h-.83v.83h-.83v-.83h-.83v-.83h-.83v.83h-.83v.83h-.83Z"
                    style={{ fill: '#fff' }}
                  />
                  <path d="M146.37,41v-5.79h1.66v1.66h3.31v.83h.83v3.31h-1.66v-3.31h-2.48v3.31h-1.66Z" style={{ fill: '#fff' }} />
                  <path
                    d="M153.81,41v-.83h-.83v-.83h.83v-.83h3.31v-.83h-3.31v-.83h4.14v.83h.83v3.31h-4.97ZM154.64,40.17h2.48v-.83h-2.48v.83Z"
                    style={{ fill: '#fff' }}
                  />
                  <path d="M160.44,41v-.83h1.66v-4.14h-.83v-.83h2.48v4.97h1.66v.83h-4.97Z" style={{ fill: '#fff' }} />
                  <path
                    d="M167.06,41v-.83h-.83v-2.48h.83v-.83h4.14v.83h.83v1.66h-4.14v.83h3.31v.83h-4.14ZM167.88,38.52h2.48v-.83h-2.48v.83Z"
                    style={{ fill: '#fff' }}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g>
          <rect height="1.19" style={{ fill: '#f3f5f7' }} width="1.44" x="25.22" y="16.63" />
          <rect height="1.19" style={{ fill: '#e24430' }} width="1.44" x="22.34" y="14.25" />
          <rect height="1.19" style={{ fill: '#eddcd4' }} width="1.44" x="30.98" y="14.25" />
          <rect height="1.19" style={{ fill: '#ebb19b' }} width="1.44" x="35.3" y="11.88" />
          <rect height="1.19" style={{ fill: '#eddad2' }} width="1.44" x="39.62" y="9.5" />
          <rect height="1.19" style={{ fill: '#ebab94' }} width="1.44" x="43.94" y="7.13" />
          <g>
            <polygon
              points="25.22 17.81 23.78 17.81 22.34 17.81 22.34 19 22.34 20.19 22.34 21.38 22.34 22.56 23.78 22.56 23.78 23.75 25.22 23.75 25.22 22.56 26.66 22.56 26.66 21.38 28.1 21.38 28.1 20.19 28.1 19 28.1 17.81 26.66 17.81 25.22 17.81"
              style={{ fill: '#eeeff0' }}
            />
            <polygon
              points="49.7 9.5 49.7 8.31 49.7 7.13 48.26 7.13 46.82 7.13 45.38 7.13 45.38 8.31 43.94 8.31 42.5 8.31 42.5 9.5 41.06 9.5 41.06 10.69 39.62 10.69 38.18 10.69 38.18 11.88 36.74 11.88 36.74 13.06 35.3 13.06 33.86 13.06 33.86 14.25 32.42 14.25 32.42 15.44 30.98 15.44 29.54 15.44 29.54 16.63 29.54 17.81 29.54 19 29.54 20.19 29.54 20.58 30.98 20.58 32.42 20.58 32.42 20.58 33.07 20.58 33.07 19 33.07 18 33.86 18 35.3 18 36.74 18 38.18 18 39.62 18 40.5 18 40.5 16.63 40.5 15.44 41.06 15.44 42.5 15.44 43.94 15.44 45.38 15.44 46.82 15.44 48.26 15.44 48.26 14.25 48.26 13.06 49.7 13.06 51.14 13.06 51.14 11.88 51.14 10.69 51.14 9.5 49.7 9.5"
              style={{ fill: '#eeeff0' }}
            />
          </g>
          <polygon
            points="43.94 4.75 43.94 3.56 42.5 3.56 42.5 2.38 41.06 2.38 39.62 2.38 39.62 1.19 38.18 1.19 36.74 1.19 36.74 0 35.3 0 33.86 0 33.86 1.19 32.42 1.19 32.42 2.38 30.98 2.38 29.54 2.38 29.54 3.56 28.1 3.56 28.1 4.75 28.1 5.94 26.66 5.94 26.66 7.13 26.66 8.31 25.22 8.31 25.22 9.5 25.22 10.69 23.78 10.69 23.78 11.88 23.78 13.06 22.34 13.06 22.34 14.25 23.78 14.25 23.78 15.44 23.78 16.63 23.78 17.81 25.22 17.81 25.22 16.63 25.22 15.44 26.66 15.44 26.66 14.25 28.1 14.25 28.1 13.06 29.54 13.06 29.54 14.25 29.54 15.44 30.98 15.44 30.98 14.25 32.42 14.25 33.86 14.25 33.86 13.06 35.3 13.06 35.3 11.88 36.74 11.88 38.18 11.88 38.18 10.69 39.62 10.69 39.62 9.5 41.06 9.5 42.5 9.5 42.5 8.31 43.94 8.31 43.94 7.13 45.38 7.13 45.38 5.94 45.38 4.75 43.94 4.75"
            style={{ fill: '#de2926' }}
          />
        </g>
      </g>
    </g>
  </svg>
);

export default NexLvlChristmasLogo;
