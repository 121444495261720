import { basicLengthAndCharValidation, basicLengthValidation } from '../../../../utils/helpers';

import consts from '../../../../utils/consts';
import { invalidRegexPassword, passwordRestrictionText } from '../../../../utils/password';

const { COMPANY_NAME, EMAIL, NAME, PASSWORD } = consts.REGEXES;

export default (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'Please enter your first name.';
  } else if (!values.firstName.match(NAME) && values.firstName.length >= 2) {
    errors.firstName =
      "Your first name can contain only letters and these characters (', -, ., space) should not be placed at the beginning or the end.";
  } else {
    errors.firstName = basicLengthAndCharValidation(values.firstName, 2, 50, NAME);
  }

  if (!values.lastName) {
    errors.lastName = 'Please enter your last name.';
  } else if (!values.lastName.match(NAME) && values.lastName.length >= 2) {
    errors.lastName =
      "Your last name can contain only letters and these characters (', -, ., space) should not be placed at the beginning or the end.";
  } else {
    errors.lastName = basicLengthAndCharValidation(values.lastName, 2, 50, NAME);
  }

  if (values.company) {
    errors.company = basicLengthAndCharValidation(values.company, 2, 50, COMPANY_NAME);
  }

  if (!values.email) {
    errors.email = "Please enter the email address you'll use to log in.";
  } else if (!values.email.match(EMAIL)) {
    errors.email = 'Please enter a valid email address.';
  } else {
    errors.email = basicLengthValidation(values.email, 2, 255);
  }

  const password = values.password;

  if (!password?.length) {
    errors.password = "Please enter the password you'll use to log in.";
  } else if (invalidRegexPassword(password)) {
    errors.password = passwordRestrictionText;
  }

  if (!values.acceptTOS) {
    errors.acceptTOS = 'Please agree to ToS and Policy';
  }

  return errors;
};
