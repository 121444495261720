import { createOfferTags } from './createOfferTags';

export const OFFER_TABLE_HEADER_KEYS = Object.freeze({
  offer: 'offer',
  payout: 'payout',
  epc: 'epc',
  payoutType: 'payoutType',
  countries: 'countries',
  status: 'status',
  preview: 'preview',
});

export const OFFER_TABLE_HEADERS = [
  {
    title: 'Offer',
    keyHeader: OFFER_TABLE_HEADER_KEYS.offer,
  },
  {
    title: 'Payout',
    keyHeader: OFFER_TABLE_HEADER_KEYS.payout,
    mobileTitle: true,
  },
  {
    title: 'EPC',
    keyHeader: OFFER_TABLE_HEADER_KEYS.epc,
    mobileTitle: true,
  },
  {
    title: 'Payout Type',
    keyHeader: OFFER_TABLE_HEADER_KEYS.payoutType,
    mobileTitle: true,
  },
  {
    title: 'Performs In',
    keyHeader: OFFER_TABLE_HEADER_KEYS.countries,
    mobileTitle: true,
  },
  {
    title: 'Status',
    keyHeader: OFFER_TABLE_HEADER_KEYS.status,
  },
  {
    title: 'Preview',
    keyHeader: OFFER_TABLE_HEADER_KEYS.preview,
  },
];

export const OFFER_TAG_TYPES = Object.freeze({
  NEW: 'new',
  EXCLUSIVE: 'exclusive',
  TOP_OFFER: 'topoffer',
  HOT_PICK: 'hotpick',
});

export const OFFER_TAG_TEXT = {
  [OFFER_TAG_TYPES.NEW]: 'New',
  [OFFER_TAG_TYPES.EXCLUSIVE]: 'Exclusive',
  [OFFER_TAG_TYPES.TOP_OFFER]: 'Top Offer',
  [OFFER_TAG_TYPES.HOT_PICK]: 'Hot Pick',
};

export const STATUSES = Object.freeze({
  APPROVED: 'approved',
  PENDING: 'pending',
  REJECTED: 'rejected',
  APPROVAL_REQUIRED: 'approval_required',
});

export const OFFER_LISTING_PAGINATION = 'offerListingPagination';

export const scrollTopOfferListingTable = () => {
  const offerListingTable = document.getElementById('offerListingTable');
  const headerDiv = document.getElementById('site-header');

  const totalScroll = offerListingTable?.offsetTop - headerDiv?.offsetHeight - 15;

  window.scroll({
    top: totalScroll,
    left: 0,
  });
};

export const sortFeaturedOffers = (offers) => {
  const taggedTopOffers = [];
  const taggedHotPickOffers = [];
  const otherOffers = [];

  offers = offers?.filter((offer) => offer.id !== process.env.REACT_APP_VAST_PREROLL_OFFER_ID);

  offers?.forEach((offer) => {
    const tags = offer.tags;

    let tagTopOffer = false;
    let tagHotPick = false;

    if (tags?.length) {
      const tagsToDisplay = createOfferTags({ tags })?.tagsToDisplay;
      tagTopOffer = tagsToDisplay?.[OFFER_TAG_TYPES.TOP_OFFER];
      tagHotPick = tagsToDisplay?.[OFFER_TAG_TYPES.HOT_PICK];
    }

    if (tagTopOffer) {
      taggedTopOffers.push(offer);
    } else if (tagHotPick) {
      taggedHotPickOffers.push(offer);
    } else {
      otherOffers.push(offer);
    }
  });

  const sortedOffers = [...taggedTopOffers, ...taggedHotPickOffers, ...otherOffers];

  return sortedOffers;
};

export const OFFERS_SORT_FEATURED_KEY = 'featured';

export const OFFERS_SORT_OPTIONS = [
  {
    value: { [OFFERS_SORT_FEATURED_KEY]: 'desc' },
    text: 'Best Match',
  },
  {
    value: { id: 'desc' },
    text: 'Newest',
  },
  {
    value: { default_payout: 'desc' },
    text: 'Highest Payout',
  },
  {
    value: { default_payout: 'asc' },
    text: 'Lowest Payout',
  },
  {
    value: { epc_affiliation: 'desc' },
    text: 'Highest EPC',
  },
  {
    value: { epc_affiliation: 'asc' },
    text: 'Lowest EPC',
  },
];
