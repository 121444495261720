import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

const ProgressBar = ({ allBadges, badge, logoLevel = 0, gamificationBadges, payoutTotal }) => {
  if (!gamificationBadges?.length) {
    return null;
  }
  let progress = 0;
  let progressBarLogoLevel = badge?.level >= 16 ? 16 : badge?.level;
  const leftIconSrc =
    progressBarLogoLevel && progressBarLogoLevel < 16 ? gamificationBadges[progressBarLogoLevel - 1].badgeData.whaleUrl : '';
  const rightIconSrc =
    gamificationBadges[progressBarLogoLevel >= 16 ? progressBarLogoLevel - 1 : progressBarLogoLevel].badgeData.whaleUrl;

  switch (badge?.type) {
    case 'login_streak': {
      const today = new Date();
      const sunday = new Date(moment().startOf('week').toDate());
      const diffTime = Math.abs(sunday - today);
      progress = (Math.floor(diffTime / (1000 * 60 * 60 * 24)) / 7) * 100;
      progressBarLogoLevel = logoLevel;
      break;
    }
    case 'payout_milestone': {
      const parsedPayoutTotal = parseFloat(payoutTotal).toFixed(2);
      const lastPayoutMilestone = badge?.badgeData?.value;
      const payoutMilestoneBadges = allBadges?.filter((badge) => badge?.type === 'payout_milestone');
      const nextPayoutValue = payoutMilestoneBadges?.find((payoutMilestone) => payoutMilestone?.level === badge?.level + 1)
        ?.badgeData?.value;
      if (nextPayoutValue < parsedPayoutTotal) {
        progress = 100;
      } else {
        progress = ((parsedPayoutTotal - (lastPayoutMilestone ?? 0)) / (nextPayoutValue - (lastPayoutMilestone ?? 0))) * 100;
      }
      break;
    }
    default:
      return null;
  }

  const whaleClassName = (img) => {
    if (img.includes('2')) {
      return 'lvl-2';
    } else if (img.includes('3')) {
      return 'lvl-3';
    } else if (img.includes('4')) {
      return 'lvl-4';
    }
    return 'lvl-1';
  };

  return (
    <div className="progress-bar-box">
      {leftIconSrc ? (
        <div className="left-icon-box">
          <img
            className={`${whaleClassName(leftIconSrc)} ${whaleClassName(leftIconSrc) === 'lvl-4' ? 'left-lvl-4' : ''}`}
            src={leftIconSrc}
          />
        </div>
      ) : null}
      <div className={`progress-bar ${leftIconSrc ? 'with-left-icon' : ''} ${rightIconSrc ? 'with-right-icon' : ''}`}>
        <div
          className={`bar ${leftIconSrc ? 'with-left-icon' : ''} ${rightIconSrc ? 'with-right-icon' : ''}`}
          style={{ width: `${progress ?? 0}%` }}
        />
      </div>
      <div className="right-icon-box">
        <img className={whaleClassName(rightIconSrc)} src={rightIconSrc} />
      </div>
    </div>
  );
};

export default connect((state) => ({
  affRank: state.profile.data.affRank,
  payoutTotal: state.payoutTotal.payoutTotal,
  allBadges: state.gamification.gamificationBadges,
}))(ProgressBar);
