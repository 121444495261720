import { cloneElement, useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import 'swiper/components/pagination/pagination.min.css';

// import Swiper core and required modules
import SwiperCore, { EffectCoverflow, Navigation, Autoplay } from 'swiper/core';

// install Swiper modules
SwiperCore.use([EffectCoverflow, Navigation, Autoplay]);

export default function SwiperSlider({
  children,
  verticalsAsString,
  loop = true,
  uid,
  spaceBetween = null,
  slidesPerView = 'auto',
  setIsSlidable,
  isSlidable = true,
  slidersWidth,
}) {
  const carouselRef = useRef();
  const [active, setActive] = useState(false);
  const currentSlideLink = useRef(null);
  let theChildren = null;
  let childrenCleaned = [];

  if (children) {
    childrenCleaned = [];
    theChildren = children.map((child) => {
      const theChild = child;
      if (theChild.props.className) {
        childrenCleaned.push(
          cloneElement(theChild, {
            ...theChild.props,
            className: theChild.props.className + ' swiper-slide',
          })
        );
      } else {
        childrenCleaned.push(
          cloneElement(theChild, {
            ...theChild.props,
            className: 'swiper-slide',
          })
        );
      }

      return theChild;
    });
  }

  useEffect(() => {
    const checkSlidable = () => {
      if (setIsSlidable) {
        if (!carouselRef.current) {
          return;
        }

        const container = carouselRef.current?.el;
        if (container) {
          const slides = container.querySelectorAll('.swiper-slide');

          const containerWidth = container.clientWidth;
          const totalSlidesWidth = Array.from(slides).reduce((acc, slide) => acc + slide.offsetWidth + spaceBetween, 0);

          setIsSlidable(totalSlidesWidth > containerWidth);
        }
      }
    };

    setTimeout(checkSlidable, 0);

    window.addEventListener('resize', checkSlidable);

    return () => {
      window.removeEventListener('resize', checkSlidable);
    };
  }, [theChildren, spaceBetween, setIsSlidable]);

  useEffect(() => {
    if (slidersWidth && carouselRef.current?.update) {
      carouselRef.current.update();
    }
  }, [slidersWidth]);

  useEffect(() => {
    setActive(false);
  }, [verticalsAsString]);

  useEffect(() => {
    if (!active) {
      setActive(true);
    }
  }, [active]);

  if (!active) {
    return null;
  }

  return (
    <Swiper
      grabCursor={isSlidable}
      loop={loop}
      navigation={{
        nextEl: `.swiper-next${uid ? `-${uid}` : ''}`,
        prevEl: `.swiper-prev${uid ? `-${uid}` : ''}`,
      }}
      noSwiping={isSlidable}
      onSwiper={(swiper) => (carouselRef.current = swiper)}
      onTouchStart={(event, targetParams) => {
        const targetLink = targetParams?.target?.closest('a')?.href;
        currentSlideLink.current = targetLink;
      }}
      onTransitionEnd={(event) => {
        const threshold = 15;
        let touchLength = event?.touches?.diff;
        touchLength = Math.abs(touchLength);
        const targetLink = currentSlideLink.current;

        if (touchLength && touchLength < threshold && targetLink) {
          window.location.href = targetLink;
        }
      }}
      preventClicks
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
    >
      {theChildren.map((child, index) => (
        <SwiperSlide key={index}>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
}
