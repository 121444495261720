import { Component } from 'react';
import PropTypes from 'prop-types';
import { asYouType, getPhoneCode } from 'libphonenumber-js';
import { injectIntl, intlShape } from 'react-intl';

import messages from '../../../../i18n/base-en.js';

import { InputText } from '../../../../components/reusables';
import { initialFetching } from '../../../../utils/initialFetching';

import {
  MESSENGERS,
  MESSENGER_PLACEHOLDERS,
} from '../../../../components/Access/TellUsAboutYou/TellUsAboutYouRolesSubdivisions/TellUsAboutYouRoleDropdownInput/TellUsAboutYouRoleDropdownInput.react.js';
import SelectCustom from '../../../../components/reusables/form/SelectCustom.js';
class ContactMethodSection extends Component {
  constructor(props) {
    super(props);
    initialFetching(props.dispatch);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dispatch, values } = this.props;

    if (values.country !== nextProps.values.country) {
      let phoneNumber;
      let phoneCode;
      let lastCountry = values.country;

      if (typeof lastCountry !== 'undefined' && lastCountry === 'UK') {
        lastCountry = 'GB';
      }

      try {
        const lastPhoneCode = typeof lastCountry !== 'undefined' ? '+' + getPhoneCode(lastCountry.toUpperCase()) : null;

        if (typeof nextProps.values.country !== 'undefined' && nextProps.values.country !== '') {
          let country = nextProps.values.country.toUpperCase();

          if (country === 'UK') {
            country = 'GB';
          }

          phoneNumber = new asYouType(country).input(nextProps.values.phone);
          phoneCode = '+' + getPhoneCode(country);

          if (phoneNumber.indexOf('+') !== -1) {
            if (lastPhoneCode !== null) {
              phoneNumber = phoneCode + phoneNumber.substr(lastPhoneCode.length);
            }
          } else {
            phoneNumber = phoneCode + ' ' + phoneNumber;
          }
        } else {
          phoneNumber = nextProps.values.phone;
        }
      } catch (e) {
        phoneNumber = nextProps.values.phone;
      }

      dispatch({
        type: 'redux-form/CHANGE',
        form: 'profileCompanyInfos',
        field: 'phone',
        value: phoneNumber,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { values, fields } = this.props;

    return (
      values.country !== nextProps.values.country ||
      values.phone !== nextProps.values.phone ||
      fields.phone.error !== nextProps.fields.phone.error ||
      fields.phone.touched !== nextProps.fields.phone.touched ||
      values.bestTimeToCall !== nextProps.values.bestTimeToCall ||
      values.bestTimeToCall !== null ||
      fields.bestTimeToCall.error !== nextProps.fields.bestTimeToCall.error ||
      fields.bestTimeToCall.touched !== nextProps.fields.bestTimeToCall.touched ||
      values.timezone !== nextProps.values.timezone ||
      fields.timezone.error !== nextProps.fields.timezone.error ||
      fields.timezone.touched !== nextProps.fields.timezone.touched ||
      values.messaging_platform_name !== nextProps.values.messaging_platform_name ||
      fields.messaging_platform_name.error !== nextProps.fields.messaging_platform_name.error ||
      fields.messaging_platform_name.touched !== nextProps.fields.messaging_platform_name.touched ||
      values.messaging_platform_username !== nextProps.values.messaging_platform_username ||
      fields.messaging_platform_username.error !== nextProps.fields.messaging_platform_username.error ||
      fields.messaging_platform_username.touched !== nextProps.fields.messaging_platform_username.touched ||
      values.website !== nextProps.values.website ||
      fields.website.error !== nextProps.fields.website.error ||
      fields.website.touched !== nextProps.fields.website.touched
    );
  }

  render() {
    const { fields, bestTimesToCall, timezones, intl } = this.props;

    const messengerValue = fields.messaging_platform_name.value;

    return (
      <div className="contact_method_section">
        <div className="row">
          <InputText
            className="col-md-12"
            displayErrorInstantly
            field={fields.phone}
            id="phone"
            label={messages.profileMainPhoneLabel}
            tabIndex={180}
            type="text"
          />
        </div>
        <div className="customSelects">
          <div className="fields">
            <SelectCustom
              id="bestTimeToCall"
              label={messages.profileBestTimeToCallLabel.description}
              onChange={fields.bestTimeToCall.onChange}
              options={bestTimesToCall.map((bestTime) => ({ value: bestTime.id, text: bestTime.name.toUpperCase() }))}
              showSelectedFieldCheckmark={false}
              tabIndex={200}
              touched={fields.bestTimeToCall.touched}
              value={fields.bestTimeToCall.value || ''}
              valueDisabled={intl.formatMessage(messages.genericTextChoose)}
            />
          </div>
          {!fields.bestTimeToCall.checked && fields.bestTimeToCall.error ? (
            <div className="row ">
              <div className="field-error select-error">{fields.bestTimeToCall.error}</div>
            </div>
          ) : null}
        </div>
        <div className="customSelects">
          <div className="fields">
            <SelectCustom
              id="timezone"
              label={messages.profileTimeZoneLabel.description}
              onChange={fields.timezone.onChange}
              options={timezones.map((timezone) => ({ value: timezone.id, text: `${timezone.location} ${timezone.gmt}` }))}
              showSelectedFieldCheckmark={false}
              tabIndex={210}
              value={`${fields.timezone.value}` || ''}
              valueDisabled={intl.formatMessage(messages.genericTextChoose)}
            />
          </div>
          {!fields.timezone.checked && fields.timezone.error ? (
            <div className="row ">
              <div className="field-error select-error">{fields.timezone.error}</div>
            </div>
          ) : null}
        </div>
        <div className="customSelects">
          <div className="fields">
            <SelectCustom
              id="messaging_platform_name"
              label="Instant Messaging Platform of Choice"
              onChange={fields.messaging_platform_name.onChange}
              options={MESSENGERS.map((messenger) => ({ value: messenger, text: messenger }))}
              showSelectedFieldCheckmark={false}
              tabIndex={220}
              value={messengerValue || ''}
              valueDisabled={intl.formatMessage(messages.genericTextChoose)}
            />
          </div>
          {!fields.messaging_platform_name.checked && fields.messaging_platform_name.error ? (
            <div className="row ">
              <div className="field-error select-error">{fields.messaging_platform_name.error}</div>
            </div>
          ) : null}
        </div>
        {messengerValue ? (
          <div className="row">
            <InputText
              className="col-md-12"
              displayErrorInstantly
              field={fields.messaging_platform_username}
              id="messaging_platform_username"
              labelString={MESSENGER_PLACEHOLDERS[messengerValue]}
              tabIndex={230}
              type="text"
            />
          </div>
        ) : null}
        <div className="row">
          <InputText
            className="col-md-12"
            displayErrorInstantly
            field={fields.website}
            id="website"
            label={messages.genericTextWebsite}
            tabIndex={240}
            type="text"
          />
        </div>
      </div>
    );
  }
}

ContactMethodSection.propTypes = {
  bestTimesToCall: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  timezones: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
};

export default injectIntl(ContactMethodSection);
