const MIN_LENGTH = 12;
const MAX_LENGTH = 16;

export const passwordRestrictionText = `Your password must contain a minimum of ${MIN_LENGTH} characters and a maximum of ${MAX_LENGTH} characters. And at least: one uppercase, one lowercase, one number and one special character.`;

export const invalidRegexPassword = (password) => {
  const length = password?.length;

  if (length < MIN_LENGTH) {
    return 'min';
  }
  if (length > MAX_LENGTH) {
    return 'max';
  }
  if (!/[A-Z]/.test(password)) {
    return 'capital';
  }
  if (!/[a-z]/.test(password)) {
    return 'lower';
  }
  if (!/\d/.test(password)) {
    return 'numeric';
  }
  if (!/\W|_/.test(password)) {
    return 'special';
  }
};
