import { invalidRegexPassword, passwordRestrictionText } from '../../utils/password';

const validatePassword = (value) => {
  let msg;
  if (!value) {
    msg = 'Required';
  } else if (invalidRegexPassword(value)) {
    msg = passwordRestrictionText;
  }
  return msg;
};

export default (values) => {
  const errors = {};

  const passwordMsg = validatePassword(values.password);
  if (passwordMsg) {
    errors.password = passwordMsg;
  }

  const passwordConfirmationMsg = validatePassword(values.passwordConfirmation);
  if (passwordConfirmationMsg) {
    errors.passwordConfirmation = passwordConfirmationMsg;
  }

  return errors;
};
