import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './styles/OfferListingSort.scss';

import { setOffersSearchFilters } from '../../components/Offers/actions/offers-actions';
import { OFFERS_SORT_OPTIONS } from './data';

const selectedOption = OFFERS_SORT_OPTIONS[0].text;

const OfferListingSort = ({ search, dispatch, isSortCta }) => {
  const [sortText, setSortText] = useState(selectedOption);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const openDropdown = (e) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const handleSort = ({ text, value }) => {
    const categoriesSort = {};
    const othersSort = {};
    const sort = {};

    const name = Object.keys(value);
    const direction = Object.values(value)[0];
    if (Object.keys(search.categories).indexOf(name) >= 0) {
      categoriesSort[name] = direction;
    } else {
      sort[name] = direction;
    }

    dispatch(
      setOffersSearchFilters({
        ...search,
        sort,
        categoriesSort,
        othersSort,
      })
    );
    setSortText(text);
    setDropdownOpen(false);
  };

  useEffect(() => {
    const closeDropdown = () => {
      if (dropdownOpen) {
        setDropdownOpen(false);
      }
    };

    const removeListener = () => document.removeEventListener('click', closeDropdown);

    if (dropdownOpen) {
      document.addEventListener('click', closeDropdown);
    } else {
      removeListener();
    }

    return () => {
      removeListener();
    };
  }, [dropdownOpen, setDropdownOpen]);

  return (
    <div className={`offerListingSort ${isSortCta ? 'isSortCta' : 'isInlineSort'}`}>
      <div className="valueBox" onClick={openDropdown}>
        <div className="valueText">Sort</div>
        {!isSortCta && (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={dropdownOpen ? 'dropdownOpen' : ''}
          >
            <g id="Regular icon">
              <path id="Vector" d="M10.2 15L15 7.8L5.4 7.8L10.2 15Z" fill="#424242" />
            </g>
          </svg>
        )}
      </div>

      {dropdownOpen && (
        <ul className="offerListingSortDropdown">
          {OFFERS_SORT_OPTIONS.map(({ text, value }) => (
            <li key={text} onClick={() => handleSort({ text, value })}>
              <span>{text}</span>
              <div className={`circleBox ${text === sortText ? 'selected' : ''}`}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Checkmark">
                    <path
                      id="Shape"
                      d="M15.8639 5.65609C16.0533 5.85704 16.0439 6.17348 15.8429 6.36288L7.91309 13.8368C7.67573 14.0605 7.30311 14.0536 7.07417 13.8213L4.39384 11.1009C4.20003 10.9042 4.20237 10.5877 4.39907 10.3938C4.59578 10.2 4.91235 10.2024 5.10616 10.3991L7.51192 12.8407L15.1571 5.63517C15.358 5.44577 15.6745 5.45513 15.8639 5.65609Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default connect((state) => ({
  search: state.offers.search,
}))(OfferListingSort);
