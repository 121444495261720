import { OFFER_TABLE_HEADERS } from './data';
import './styles/OfferListingTable.scss';
import EpcTooltipIcon from '../Offers/EpcTooltipIcon';

const OfferListingRowHeader = () => (
  <div className="offerListingRow offerListingRowHeader">
    {OFFER_TABLE_HEADERS.map(({ keyHeader, title }) => (
      <div className={`offerListingGridColumn column-${keyHeader}`} key={keyHeader}>
        {title}
        {keyHeader === 'epc' && <EpcTooltipIcon id="tooltip-epc" />}
      </div>
    ))}
  </div>
);

export default OfferListingRowHeader;
