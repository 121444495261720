import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { Checkbox, SimplifiedInput } from '../../../components/reusables';
import SkinConfigurationsUtils from '../../../utils/SkinConfigurationsUtils';
import GoogleSignup from '../../../components/reusables/svg/GoogleSignUp.react';
import RefreshButton from '../../../components/reusables/svg/RefreshButton.react';
import { checkIsIpVpn } from '../../../actions/register-actions';
import { default as validate } from './ValidateCreateAccount/validateCreateAccount';

import config from '../../../config';
import { passwordRestrictionText } from '../../../utils/password.js';
const { RECAPTCHA_SITE_KEY } = config;

const CreateAccount = ({
  createAccountError,
  fields,
  googleIsLoading,
  handleGoogleSignup,
  handleSubmit,
  signupIsFetching,
  skinConfigurations,
}) => {
  let captcha;
  const [cypress, setCypress] = useState(false);
  const [isUnderVpn, setIsUnderVpn] = useState(false);

  const checkForVpn = () => {
    checkIsIpVpn().then((res) => {
      setIsUnderVpn(res);
    });
  };

  useEffect(() => {
    checkForVpn();
    if (window.Cypress) {
      setCypress(true);
    }
    const referrer = document.referrer;
    // checks if there is a referrer that isn't an empty string to set in localStorage
    if (referrer.length) {
      localStorage.setItem('previousUrl', referrer);
    }
  }, []);

  useEffect(() => {
    captcha.reset();
  }, [createAccountError]);

  const refreshMessage = () => {
    setIsUnderVpn(false);
    checkForVpn();
  };

  const SKIN = new SkinConfigurationsUtils(skinConfigurations);

  const LinkSingIn = (
    <a href="/login">
      <FormattedMessage {...messages.createAccountAlreadyHaveOneSingInText} />
    </a>
  );
  messages.createAccountAlreadyHaveOneSingInLink.values = {
    link: LinkSingIn,
  };

  const LinksingUpAdvisor = (
    <a href="http://portal.crakrevenue.com/advertiser_signup">
      <FormattedMessage {...messages.createAccountAdvertiserLinkText} />
    </a>
  );
  messages.createAccountAdvertiserLink.values = {
    link: LinksingUpAdvisor,
  };

  let tabIndex = 0;

  return (
    <div id="simplified-create-account">
      {isUnderVpn ? (
        <div className="vpn-information">
          <span className="title">
            <FormattedMessage {...messages.createAccountVpnTitle} />
          </span>
          <div className="description">
            <p>
              <FormattedMessage {...messages.createAccountVpnDescription} />
            </p>
            <p>
              <button className="btn" onClick={refreshMessage}>
                <RefreshButton />
              </button>
            </p>
          </div>
        </div>
      ) : null}
      <div className="form-div">
        <div className="row">
          <h1 className="create-account-title">
            <FormattedMessage {...messages.createAccount} />
          </h1>
        </div>
        {SKIN.isItemVisible('CreateAccount', 'GoogleSignup') && (
          <div className="simple-signup__google-signup-container row">
            <div className="row">
              <GoogleSignup disabled={googleIsLoading} label="Sign up with Google" onClick={handleGoogleSignup} />
            </div>
            <div className="simple-signup__google-bot-border">
              <span>OR</span>
            </div>
          </div>
        )}
        <div className="row">
          <form
            action="#"
            className="form-container col-md-12"
            id="create-account-form"
            method="post"
            // ref='createAccount'
            onSubmit={handleSubmit}
          >
            <div className="row">
              <SimplifiedInput
                autofocus
                className="simple-signup__input"
                field={fields.firstName}
                id="simplified_firstname"
                label={messages.createAccountFirstName}
                maxLength={50}
                tabIndex={++tabIndex}
                type="text"
              />
            </div>
            <div className="row">
              <SimplifiedInput
                autofocus
                className="simple-signup__input"
                field={fields.lastName}
                id="simplified_lastname"
                label={messages.createAccountLastName}
                maxLength={50}
                tabIndex={++tabIndex}
                type="text"
              />
            </div>
            <div className="row">
              <SimplifiedInput
                autofocus
                className="simple-signup__input"
                field={fields.company}
                id="simplified_company"
                label={messages.createAccountCompany}
                maxLength={50}
                optional
                tabIndex={++tabIndex}
                type="text"
              />
            </div>
            <div className="row">
              <SimplifiedInput
                autofocus
                className="simple-signup__input"
                field={fields.email}
                id="simplified_email"
                label={messages.createAccountEmail}
                tabIndex={++tabIndex}
                type="text"
              />
            </div>
            <div className="row">
              <SimplifiedInput
                autofocus
                className="simple-signup__input"
                field={fields.password}
                id="simplified_password"
                label={messages.createAccountPassword}
                tabIndex={++tabIndex}
                tooltip={passwordRestrictionText}
                type="password"
              />
            </div>
            <div className="row" id="simplified_accept_tos">
              <Checkbox
                className="simple-signup__checkbox"
                escapeHtml
                field={fields.acceptTOS}
                id="create-account-accept-terms"
                label={messages.createAccountToSLink}
                tabIndex={++tabIndex}
              />
            </div>
            {cypress === false && (
              <div className="row">
                <ReCAPTCHA
                  className="recaptcha"
                  onChange={fields.recaptcha.onChange}
                  ref={(e) => (captcha = e)}
                  sitekey={RECAPTCHA_SITE_KEY}
                />

                {fields.recaptcha.error ? <div className="field-error">{fields.recaptcha.error}</div> : null}
              </div>
            )}
            <div className="row">
              {createAccountError ? (
                <div
                  className="field-error big"
                  dangerouslySetInnerHTML={{
                    __html: createAccountError,
                  }}
                />
              ) : null}
              <div className="col-md-12 form-buttons text-right" id="create-account-send-form">
                <Link
                  className="link-confirm-account"
                  onlyActiveOnIndex={false}
                  to={{
                    pathname: '/access/confirm-email',
                    state: { email: fields.email },
                  }}
                >
                  <button
                    className="simple-signup__im-in waves-effect waves-light btn"
                    disabled={signupIsFetching}
                    onClick={handleSubmit}
                  >
                    <FormattedMessage {...messages.createAccountImIn} />
                  </button>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="under-form">
        <p>
          <FormattedMessage {...messages.createAccountAlreadyHaveOneSingInLink} />
        </p>
        <p>
          <FormattedMessage {...messages.createAccountAdvertiserLink} />
        </p>
      </div>
    </div>
  );
};

CreateAccount.defaultProps = {
  googleIsLoading: false,
};

CreateAccount.propTypes = {
  createAccountError: PropTypes.any,
  fields: PropTypes.object.isRequired,
  googleIsLoading: PropTypes.bool,
  handleGoogleSignup: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  signupIsFetching: PropTypes.bool.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'createAccount',
  fields: ['firstName', 'lastName', 'company', 'email', 'password', 'acceptTOS', 'recaptcha'],
  initialValues: {
    recaptcha: 'NOT-CHECKED',
  },
  validate,
})(
  connect((state) => ({
    createAccountError: state.signup.err?.message,
    signupIsFetching: state.signup.isFetching,
    skinConfigurations: state.skinConfigurations.data,
  }))(CreateAccount)
);
