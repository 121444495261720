import { passwordRestrictionText } from '../../../utils/password';

export default {
  'userAndCompany.newPasswordLabel': 'New Password',
  'userAndCompany.newPasswordConfirmationLabel': 'New Password Confirmation',
  'userAndCompany.userAndCompanyChangePassword': 'Change your password',
  'userAndCompany.userAndCompanyChangePasswordMessage': passwordRestrictionText,
  'userAndCompany.updateProfileInfosCompleted': 'Your profile informations has been updated successfully!',
  'userAndCompany.updateConnectionInfosCompleted': 'Your connection informations has been updated successfully!',
};
