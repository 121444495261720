import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import CloseModalIcon from './svg/Gamification/CloseModalIcon.react';

const Tooltip = ({
  id,
  children,
  className,
  closeDelay = 150,
  closeIcon,
  content,
  onOpen = () => {},
  onClose = () => {},
  position = 'bottom',
  isHoverable = true,
  isFixed = false,
  wrapperClassName = '',
  isDesktopClickable = true,
}) => {
  const [visible, setVisible] = useState(false);
  const tooltipWidth = 200;
  const tooltipMargin = 10;
  const [tooltipStyle, setTooltipStyle] = useState({ width: `${tooltipWidth}px` });
  const [tooltipPosition, setTooltipPosition] = useState(position);
  const hideTimeoutRef = useRef(null);
  const hoverTimeoutRef = useRef(null); // For hover delay
  const tooltipRef = useRef(null);
  const wrapperRef = useRef(null);
  const isTouchEvent = useRef(false);
  const ignoreTouchAfterCloseRef = useRef(false);
  const [windowWidthSize, setWindowWidthSize] = useState(undefined);
  useEffect(() => {
    function handleResize() {
      setWindowWidthSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const showTooltip = (coordinates) => {
    let tooltipPositionCalc = position;
    const wrapperRect = wrapperRef?.current?.getBoundingClientRect();
    if (wrapperRect) {
      //check if tooltip is too big to be placed on asked position.
      //If so, try to replace it to the opposite position or at the bottom of the target
      const wrapperWidth = wrapperRect.width;
      const targetLeft = wrapperRect.left;
      const targetRight = wrapperRect.right;
      const tooltipSpace = tooltipWidth + tooltipMargin;
      if (tooltipPositionCalc === 'left' && targetLeft - tooltipSpace < 0) {
        if (targetRight + tooltipSpace < window.innerWidth) {
          tooltipPositionCalc = 'right';
        } else if (targetRight - wrapperWidth / 2 + tooltipSpace / 2 < window.innerWidth) {
          tooltipPositionCalc = 'bottom';
        }
      } else if (tooltipPositionCalc === 'right' && tooltipSpace > window.innerWidth - targetRight) {
        if (targetLeft - tooltipSpace > 0) {
          tooltipPositionCalc = 'left';
        } else if (targetLeft - wrapperWidth / 2 - tooltipSpace / 2 > 0) {
          tooltipPositionCalc = 'bottom';
        }
      }
    }

    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }
    if (visible) {
      return;
    }

    const { top, left, height, width } = coordinates;
    let newTooltipStyle = {};

    const scrollY = isFixed ? 0 : window.scrollY;
    const scrollX = isFixed ? 0 : window.scrollX;

    switch (tooltipPositionCalc) {
      case 'left':
        newTooltipStyle = {
          top: top + scrollY + height / 2,
          left: left + scrollX - tooltipMargin,
          transform: 'translateX(-100%) translateY(-50%)',
        };
        break;
      case 'right':
        newTooltipStyle = {
          top: top + scrollY + height / 2,
          left: left + scrollX + width + tooltipMargin,
          transform: 'translateY(-50%)',
        };
        break;
      case 'top':
        newTooltipStyle = {
          top: top + scrollY - tooltipMargin,
          left: left + scrollX + width / 2,
          transform: 'translateX(-50%) translateY(-100%)',
        };
        break;
      case 'bottom':
      default:
        newTooltipStyle = {
          top: top + scrollY + height + tooltipMargin,
          left: left + scrollX + width / 2,
          transform: 'translateX(-50%)',
        };
        break;
    }

    if (isFixed) {
      newTooltipStyle = { ...newTooltipStyle, position: 'fixed' };
    }
    setTooltipStyle({ ...tooltipStyle, ...newTooltipStyle });
    setTooltipPosition(tooltipPositionCalc);
    setVisible(true);
  };

  const hideTooltip = useCallback(
    (event, useTimer = true) => {
      if (!visible) {
        return;
      }
      if (useTimer) {
        hideTimeoutRef.current = setTimeout(() => {
          setVisible(false);
          hideTimeoutRef.current = null;
          ignoreTouchAfterCloseRef.current = true;
          setTimeout(() => {
            ignoreTouchAfterCloseRef.current = false;
          }, 150);
        }, closeDelay);
      } else {
        setVisible(false);
        hideTimeoutRef.current = null;
        ignoreTouchAfterCloseRef.current = true;
        setTimeout(() => {
          ignoreTouchAfterCloseRef.current = false;
        }, 150);
      }
      onClose();
    },
    [closeDelay, onClose, visible]
  );

  const debouncedShowTooltip = debounce((coordinates) => showTooltip(coordinates), 150);
  const debouncedHideTooltip = debounce((event) => hideTooltip(event, false), 150);

  const handleTooltipClick = (event) => {
    event.stopPropagation();
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (
        event.target instanceof Node &&
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target) &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        hideTooltip(event, false);
      }
    },
    [hideTooltip]
  );

  const handleOnMouseLeave = useCallback(
    (event) => {
      if (
        event.target instanceof Node &&
        tooltipRef.current &&
        event.relatedTarget instanceof Node &&
        !tooltipRef.current.contains(event.relatedTarget) &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.relatedTarget)
      ) {
        hideTooltip(event, true);
      }
    },
    [hideTooltip]
  );

  const handleMouseEnter = (event) => {
    if (isHoverable) {
      const rect = event.currentTarget.getBoundingClientRect();
      const coordinates = {
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
        right: rect.right,
      };
      hoverTimeoutRef.current = setTimeout(() => {
        showTooltip(coordinates);
      }, 500); // 500ms delay
    }
  };

  const handleMouseLeave = (event) => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    if (isHoverable) {
      handleOnMouseLeave(event);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (windowWidthSize >= 992 && isDesktopClickable) {
      if (isTouchEvent.current) {
        isTouchEvent.current = false;
        return;
      }
      const rect = event.currentTarget.getBoundingClientRect();
      const coordinates = {
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
      };
      if (!visible) {
        debouncedShowTooltip(coordinates);
      } else {
        debouncedHideTooltip(event);
      }
    }
  };

  const handleTouchStart = (event) => {
    if (ignoreTouchAfterCloseRef.current) {
      return;
    }

    isTouchEvent.current = true;
    const rect = event.currentTarget.getBoundingClientRect();
    const coordinates = {
      top: rect.top,
      left: rect.left,
      width: rect.width,
      height: rect.height,
    };
    if (!visible) {
      debouncedShowTooltip(coordinates);
    } else {
      debouncedHideTooltip(event);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (visible) {
      onOpen();
    }
  }, [visible]);

  const tooltipElement = visible ? (
    <>
      <button className="tooltip-button" onClick={(event) => hideTooltip(event, false)} type="button">
        <CloseModalIcon />
      </button>
      <div
        className={`${className} tooltip`}
        data-position={tooltipPosition}
        id={id ? id : 'tooltip-custom'}
        onClick={handleTooltipClick}
        onMouseEnter={(e) => {
          showTooltip(e);
        }}
        onMouseLeave={(e) => {
          handleOnMouseLeave(e);
        }}
        onTouchStart={handleTooltipClick}
        ref={tooltipRef}
        style={tooltipStyle}
      >
        {content}
        {closeIcon ? (
          <button className="tooltip-close" onClick={(event) => hideTooltip(event, false)} type="button">
            <CloseModalIcon />
          </button>
        ) : null}
      </div>
    </>
  ) : null;

  return (
    <div
      className={`${wrapperClassName} tooltip-wrapper`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      ref={wrapperRef}
    >
      <div>{children}</div>
      {createPortal(tooltipElement, document.body)}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  closeDelay: PropTypes.number,
  closeIcon: PropTypes.bool,
  content: PropTypes.node,
  id: PropTypes.string,
  isHoverable: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};

Tooltip.defaultProps = {
  position: 'bottom',
};

export default Tooltip;
