import { passwordRestrictionText } from '../../../utils/password';

export default {
  userAndCompanyNewPasswordLabel: {
    id: 'userAndCompany.newPasswordLabel',
    description: 'New Password',
    defaultMessage: 'New Password',
  },
  userAndCompanyNewPasswordConfirmationLabel: {
    id: 'userAndCompany.newPasswordConfirmationLabel',
    description: 'New Password Confirmation',
    defaultMessage: 'New Password Confirmation',
  },
  userAndCompanyChangePassword: {
    id: 'userAndCompany.userAndCompanyChangePassword',
    description: 'Change your password',
    defaultMessage: 'Change your password',
  },
  userAndCompanyChangePasswordMessage: {
    id: 'userAndCompany.userAndCompanyChangePasswordMessage',
    description: passwordRestrictionText,
    defaultMessage: passwordRestrictionText,
  },
  userAndCompanyUpdateProfileInfosCompleted: {
    id: 'userAndCompany.updateProfileInfosCompleted',
    description: 'Your profile information has been updated successfully!',
    defaultMessage: 'Your profile information has been updated successfully!',
  },
  userAndCompanyUpdateConnectionInfosCompleted: {
    id: 'userAndCompany.updateConnectionInfosCompleted',
    description: 'Your connection information has been updated successfully!',
    defaultMessage: 'Your connection information has been updated successfully!',
  },
};
